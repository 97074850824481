import React, { createContext, useContext, useState } from "react";

const WatchSlipContext = createContext();

export default function WatchSlipContextProvider({ children }) {
  const [watchSlipCount, setWatchSlipCount] = useState(0);

  const value = {
    watchSlipCount,
    updateMobileNavWatchSlipCount: setWatchSlipCount,
  };
  return (
    <WatchSlipContext.Provider value={value}>
      {children}
    </WatchSlipContext.Provider>
  );
}

export const useWatchSlipContext = () => useContext(WatchSlipContext);
