import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Moment from "react-moment";
import Button from "@material-ui/core/Button";
import "./WatchSlipEntries.scss";
import Spinner from "../../components/Spinner/Spinner";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import emptySlip from "assets/emptyWatchSlip.png";

import Toast from "../../components/Toast/Toast";

const WatchSlipEntries = ({
  watchSlipData,
  isSubmitting,
  onRemoveWatchSlipEntry,
  onPointsChange,
  onPriceChange,
  onToggleChange,
  onTogglePriceChange,
  currentWatchSlipsRemaining,
  isWatchSlipError,
  onWatchSlipError,
  isSuccess,
}) => {
  const [hack, setHack] = useState(false);
  useEffect(() => {}, [hack]);
  if (watchSlipData.length === 0) {
    return (
      <div className="emptyWatchSlip">
        {isSuccess && (
          <Toast
            open={isSuccess}
            duration={3000}
            onClose={onWatchSlipError}
            severity="success"
            text="Watch Slip Placed!"
          />
        )}
        <img src={emptySlip} alt="emptySlip" />
        <h6>Watch Slip Entries is Empty.</h6>
      </div>
    );
  }
  if (isSubmitting) {
    return (
      <div className="watchSlipLoading">
        <Spinner size={60} />
      </div>
    );
  }

  const toggle = (index, value) => {
    setHack(!hack);
    onToggleChange(index, value);
  };

  const pointsChange = (index, value) => {
    setHack(!hack);
    onPointsChange(index, value);
  };

  const priceChange = (index, value) => {
    setHack(!hack);
    onPriceChange(index, value);
  };

  const plusOrMinusPriceChange = (index, value) => {
    setHack(!hack);
    onTogglePriceChange(index, value);
  };

  const isError = (value) => {
    return (
      value &&
      value !== undefined &&
      value !== "+" &&
      value !== "-" &&
      (isNaN(value) ||
        (value % 1 !== 0 && value % 1 !== 0.5 && value % 1 !== -0.5))
    );
  };

  const showLockedText = (index) => {
    if (currentWatchSlipsRemaining >= 0) {
      return false;
    } else if (index >= watchSlipData.length + currentWatchSlipsRemaining) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {isWatchSlipError && (
        <Toast
          open={isWatchSlipError}
          duration={3000}
          onClose={onWatchSlipError}
          severity="error"
          text="Woops! Please try again."
        />
      )}
      {watchSlipData.map((slipData, index) => (
        <div key={slipData.uniqueId}>
          <Button
            disableRipple
            onClick={() => onRemoveWatchSlipEntry(index)}
            color="secondary"
            className="xButton"
          >
            X
          </Button>
          <h5 className="betPicked">
            {slipData.pick.toLowerCase()} {slipData.currentBet}{" "}
            {slipData.currentBetPrice
              ? "(" + slipData.currentBetPrice + ")"
              : ""}
          </h5>
          <div className="line"></div>
          <h5 className="betType">{slipData.betType}</h5>
          <h6>
            <Moment format="MM/DD/YY h:mm A">{slipData.startDate}</Moment>
          </h6>
          <h6 className="betType">
            {slipData.awayTeam.toLowerCase()} {" @ "}
            {slipData.homeTeam.toLowerCase()}
          </h6>
          <br />
          <div className="buttonsAndSlip">
            <div className="button-price-group">
              {slipData.betType !== "Total" && (
                <div className="button-group">
                  <button
                    onClick={() => toggle(index, "plus")}
                    className={
                      slipData.plusOrMinus === "plus"
                        ? "plus-button-clicked"
                        : "button-not-clicked"
                    }
                  >
                    +
                  </button>
                  <button
                    onClick={() => toggle(index, "minus")}
                    className={
                      slipData.plusOrMinus === "minus"
                        ? "minus-button-clicked"
                        : "button-not-clicked"
                    }
                  >
                    -
                  </button>
                </div>
              )}
              <TextField
                id="points"
                onChange={(e) => pointsChange(index, e.target.value)}
                label="Watch Bet*"
                variant="outlined"
                value={slipData.watchBet}
                className={
                  slipData.plusOrMinus === "plus" && slipData.pick !== "Under"
                    ? "watch-bet-input-plus"
                    : "watch-bet-input-minus"
                }
                placeholder={slipData.currentBet}
                inputProps={{
                  inputmode: "decimal",
                  pattern: "[0-9]*",
                  step: "0.01",
                }}
                InputProps={{
                  startAdornment: slipData.betType === "Total" && (
                    <InputAdornment
                      style={{
                        color: slipData.pick === "Over" ? "green" : "red",
                      }}
                    >
                      <div style={{ fontSize: "20px" }}>
                        {slipData.pick === "Over" ? "o" : "u"}
                      </div>
                    </InputAdornment>
                  ),
                }}
                autoComplete="off"
                error={isError(slipData.watchBet) || showLockedText(index)}
                helperText={
                  isError(slipData.watchBet) ? (
                    "Only whole and half-integers allowed"
                  ) : showLockedText(index) ? (
                    <h4 className="daily-limit-text">
                      Reached daily limit! Check back tomorrow or{" "}
                      <a className="upgrade-link" href="/pricing">
                        upgrade
                      </a>{" "}
                      your plan!
                    </h4>
                  ) : (
                    ""
                  )
                }
              />
              {index === 0 && (
                <Tooltip
                  title={
                    <h4>
                      This field is required! A notification will be sent if the
                      odds reach the threshold you set
                    </h4>
                  }
                  style={{ fontSize: "18px", color: "grey" }}
                  placement="bottom"
                >
                  <InfoIcon className="info-icon" />
                </Tooltip>
              )}
            </div>
            <div className="button-price-group">
              {slipData.betType !== "Moneyline" && (
                <div className="buttonsAndSlip">
                  <div className="button-group">
                    <button
                      onClick={() => plusOrMinusPriceChange(index, "plus")}
                      className={
                        slipData.plusOrMinusPrice === "plus"
                          ? "plus-button-clicked"
                          : "button-not-clicked"
                      }
                    >
                      +
                    </button>
                    <button
                      onClick={() => plusOrMinusPriceChange(index, "minus")}
                      className={
                        slipData.plusOrMinusPrice === "minus"
                          ? "minus-button-clicked"
                          : "button-not-clicked"
                      }
                    >
                      -
                    </button>
                  </div>
                  <TextField
                    id="price"
                    onChange={(e) => priceChange(index, e.target.value)}
                    label="Watch Price"
                    variant="outlined"
                    value={slipData.watchBetPrice}
                    className={
                      slipData.plusOrMinusPrice === "plus"
                        ? "watch-bet-input-plus"
                        : "watch-bet-input-minus"
                    }
                    placeholder={
                      slipData.plusOrMinusPrice === "plus" ? "+100" : "-100"
                    }
                    inputProps={{
                      inputmode: "decimal",
                      pattern: "[0-9]*",
                      step: "0.01",
                    }}
                    autoComplete="off"
                    error={
                      isError(slipData.watchBetPrice) || showLockedText(index)
                    }
                    helperText={
                      isError(slipData.watchBet)
                        ? "Only whole and half-integers allowed"
                        : ""
                    }
                  />
                  {index === 0 && (
                    <Tooltip
                      title={
                        <h4>
                          This field is optional! A notification will be sent
                          only if the current price and bet are better than the
                          thresholds you set
                        </h4>
                      }
                      style={{ fontSize: "18px", color: "grey" }}
                      placement="bottom"
                    >
                      <InfoIcon className="info-icon" />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
          </div>
          <br />
          <hr />
        </div>
      ))}
    </div>
  );
};

export default WatchSlipEntries;
