import "./About.scss";
import React, { useState } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ContentSupported from "containers/ContentSupported/ContentSupported";
import LoginOverlay from "containers/Login/LoginOverlay";
import SignUpOverlay from "containers/SignUp/SignUpOverlay";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Footer from "../../components/Footer/Footer";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { MailingListSignupForm } from "components";
import { HOW_IT_WORKS, FAQS } from "./content";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles({
  button: {
    width: 125,
    padding: "10px 0px",
    borderRadius: 10,
    margin: 10,
    fontSize: 16,
  },
});

const StyledBadge = withStyles({
  badge: {
    fontSize: "2.2rem",
    padding: "15px 10px",
  },
})(Badge);

const About = ({ history, setAuthenticated, mobile }) => {
  const [status, setStatus] = useState("IDLE"); // IDLE | LOGIN | SIGNUP
  const classes = useStyles();
  const isLogin = status === "LOGIN";
  const isSignUp = status === "SIGNUP";

  const handleLoggedInSuccessfully = () => {
    const data = localStorage.getItem("sport-odds");
    if (data) {
      history.push(data);
    } else {
      // hard coding to basketball
      // TODO: fix custom:book error when clearing application cache
      history.push("/sports/baseball/mlb");
    }
    setAuthenticated(true);
  };

  const handleSignUpSuccessfully = () => {
    // hard coding to basketball
    // history.push("/sports/basketball/nba?isNewUser=true");
    history.push("/sports/baseball/mlb");
    setAuthenticated(true);
  };

  const handleLogin = () => setStatus("LOGIN");
  const handleSignUp = () => setStatus("SIGNUP");
  const handleClose = () => setStatus("IDLE");

  return (
    <div id="about">
      {isLogin && (
        <LoginOverlay
          isLogin={isLogin}
          handleClose={handleClose}
          handleLoggedInSuccessfully={handleLoggedInSuccessfully}
        />
      )}
      {isSignUp && (
        <SignUpOverlay
          isSignUp={isSignUp}
          handleClose={handleClose}
          handleSignUpSuccessfully={handleSignUpSuccessfully}
        />
      )}
      <div id="top-section">
        <h1 className="logo">
          <i>Odds</i>Eye
        </h1>
        <h3>
          Get texted when the odds get better.
          <br /> Win your bets.
        </h3>
        <Grid container justify="center">
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleLogin}
          >
            Login
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleSignUp}
          >
            Sign up
          </Button>
        </Grid>
      </div>
      <div id="how-it-works">
        <h2>How It Works</h2>
        {HOW_IT_WORKS.map((section) => (
          <div key={section.id} className="hiw-section">
            <div className="header">
              <div className="circle">{section.id}</div>
              <h3>{section.heading}</h3>
            </div>
            {section.content && (
              <div className="content">
                <div className="line"></div>
                <p>{section.content}</p>
                <img alt="step" src={section.image} />
              </div>
            )}
          </div>
        ))}
      </div>

      <ContentSupported />

      <div id="faq">
        <h2 className="questions">Frequently Asked Questions</h2>
        {FAQS.map((faq) => (
          <ExpansionPanel key={faq.id}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className="questionText">{faq.question}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography className="answersText">{faq.answer}</Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </div>
      {mobile && <Footer />}
    </div>
  );
};

export default About;
