import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SportsBar.scss";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import SportsBaseballIcon from "@material-ui/icons/SportsBaseball";
import SportsHockeyIcon from "@material-ui/icons/SportsHockey";
import SportsMmaIcon from "@material-ui/icons/SportsMma";
import MoreIcon from "@material-ui/icons/More";
import SportsFootballIcon from "@material-ui/icons/SportsFootball";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import GolfCourseIcon from "@material-ui/icons/GolfCourse";
import SportsTennisIcon from "@material-ui/icons/SportsTennis";
import SportsSoccerIcon from "@material-ui/icons/SportsSoccer";

const SportsBar = ({ isAuthenticated, history, showSlip, mobile }) => {
  const [toggleExtraMenu, setToggleExtraMenu] = useState(false);

  const toggleMenu = () => {
    setToggleExtraMenu(!toggleExtraMenu);
  };

  const handleClick = (url) => {
    localStorage.setItem("sport-odds", url);
  };

  if (showSlip && mobile) {
    return <div></div>;
  }

  const renderExtraMenu = () => {
    return (
      toggleExtraMenu && (
        <div id="extra-sports-bar">
          <Link to="/" className="sports-icon-cont">
            <SportsEsportsIcon className="sports-icon" />
            <h4 className="text">eSports</h4>
          </Link>

          <Link to="/" className="sports-icon-cont">
            <GolfCourseIcon className="sports-icon" />
            <h4 className="text">Golf</h4>
          </Link>

          <Link to="/" className="sports-icon-cont">
            <SportsTennisIcon className="sports-icon" />
            <h4 className="text">Tennis</h4>
          </Link>
          <Link to="/" className="sports-icon-cont">
            <SportsSoccerIcon className="sports-icon" />
            <h4 className="text">Soccer</h4>
          </Link>
        </div>
      )
    );
  };

  return (
    isAuthenticated && (
      <div id="sports-bar" className="sports-bar-2">
        <Link
          to="/sports/football/nfl"
          className={
            history.location.pathname === "/sports/football/nfl"
              ? "sports-icon-clicked-cont"
              : "sports-icon-cont"
          }
          onClick={() => handleClick("/sports/football/nfl")}
        >
          <SportsFootballIcon className="sports-icon" />
          <h4 className="text">Football</h4>
        </Link>
        <Link
          to="/sports/baseball/mlb"
          className={
            history.location.pathname === "/sports/baseball/mlb"
              ? "sports-icon-clicked-cont"
              : "sports-icon-cont"
          }
          onClick={() => handleClick("/sports/baseball/mlb")}
        >
          <SportsBaseballIcon className="sports-icon" />
          <h4 className="text">Baseball</h4>
        </Link>
        <Link
          to="/sports/mixed-martial-arts/ufc"
          className={
            history.location.pathname === "/sports/mixed-martial-arts/ufc"
              ? "sports-icon-clicked-cont"
              : "sports-icon-cont"
          }
          onClick={() => handleClick("/sports/mixed-martial-arts/ufc")}
        >
          <SportsMmaIcon className="sports-icon" />
          <h4 className="text">Fighting</h4>
        </Link>
        <Link
          to="/sports/basketball/nba"
          className="sports-icon-cont-disabled"
          onClick={() => handleClick("/sports/baseball/mlb")}
        >
          <SportsBasketballIcon className="sports-icon" />
          <h4 className="text">Basketball</h4>
        </Link>
        <Link
          to="/sports/hockey/nhl"
          className="sports-icon-cont-disabled"
          onClick={() => handleClick("/sports/hockey/nhl")}
        >
          <SportsHockeyIcon className="sports-icon" />
          <h4 className="text">Hockey</h4>
        </Link>

        {/* <Link to="/" onClick={toggleMenu} className="sports-icon-cont">
          <MoreIcon className="sports-icon" />
          <h4 className="text">More</h4>
        </Link>
        {renderExtraMenu()} */}
      </div>
    )
  );
};

export default SportsBar;
