import { createMuiTheme } from "@material-ui/core/styles";
import pink from "@material-ui/core/colors/pink";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

export default createMuiTheme({
  palette: {
    secondary: {
      main: pink[400],
    },
    success: {
      main: green[600],
    },
    action: {
      disabledBackground: grey[200],
    },
  },
  typography: {
    fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif`,
    fontWeight: 400,
    fontSize: "12px",
  },
});
