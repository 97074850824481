import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Navbar } from "react-bootstrap";
import "./Questions.scss";

const Questions = () => {
  return (
    <div>
      <Navbar className="Questions">
        <Typography className="text">Frequently Asked Questions</Typography>
      </Navbar>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 1</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 1</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 2</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 2</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 3</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 3</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 4</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 4</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 5</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 5</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 6</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 6</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 7</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 7</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Question 8</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>Answer to Question 8</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default Questions;
