import React from "react";
import "./EntryStatistics.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SportsBaseballIcon from "@material-ui/icons/SportsBaseball";
import SportsMmaIcon from "@material-ui/icons/SportsMma";
import SportsHockeyIcon from "@material-ui/icons/SportsHockey";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import SportsFootballIcon from "@material-ui/icons/SportsFootball";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

const parseStats = (data) => {
  let map = [];
  let sports = [];
  for (let i = 0; i < data.length; i++) {
    if (!sports.includes(data[i].sport)) {
      sports.push(data[i].sport);
      map.push({
        hits: 0,
        misses: 0,
        pushes: 0,
        sport: data[i].sport,
      });
    }
    for (let j = 0; j < map.length; j++) {
      if (map[j].sport === data[i].sport) {
        if (data[i].status === "HIT") {
          map[j].hits += 1;
        } else if (data[i].status === "MISS") {
          map[j].misses += 1;
        } else if (data[i].status === "PUSH") {
          map[j].pushes += 1;
        }
      }
    }
  }
  return map;
};

const EntryStatistics = ({ activeSlipData }) => {
  const statistics = parseStats(activeSlipData);

  const findIcon = (sport) => {
    switch (sport) {
      case "Mixed Martial Arts":
        return <SportsMmaIcon />;
      case "Football":
        return <SportsFootballIcon />;
      case "Baseball":
        return <SportsBaseballIcon />;
      case "Hockey":
        return <SportsHockeyIcon />;
      case "Basketball":
        return <SportsBasketballIcon />;
      default:
        return "";
    }
  };

  const calculatePercentage = (hits, misses, pushes) => {
    const totalGames = hits + misses + pushes;
    if (totalGames === 0) {
      return 0;
    }
    return Math.round(((2 * hits + pushes) / (2 * totalGames)) * 100);
  };

  return (
    <div className="entry-statistics-root">
      <div className="alert-statistics-title">
        <h3>Watch Slip Statistics</h3>
        <Tooltip
          title={
            <h4>
              Watch Slip statistics will only show games from the respective
              month
            </h4>
          }
          style={{ fontSize: "24px" }}
          placement="bottom"
        >
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      {/* {statistics && statistics.length === 0 && (
        <h5>
          Alert Statistics is Empty! Only games that were notified on will show
          here!
        </h5>
      )} */}
      <TableContainer className="root">
        <Table className="table">
          <TableHead className="columns">
            <TableRow>
              <TableCell></TableCell>
              <TableCell className="cell">Sport</TableCell>
              <TableCell align="center" className="cell">
                Hits
              </TableCell>
              <TableCell align="center" className="cell">
                Misses
              </TableCell>
              <TableCell align="center" className="cell">
                Pushes
              </TableCell>
              <TableCell align="center" className="cell">
                Percentage
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statistics.map((data) => (
              <TableRow>
                <TableCell className="cell">{findIcon(data.sport)}</TableCell>
                <TableCell className="cell">{data.sport}</TableCell>
                <TableCell align="center" className="cell">
                  {data.hits}
                </TableCell>
                <TableCell align="center" className="cell">
                  {data.misses}
                </TableCell>
                <TableCell align="center" className="cell">
                  {data.pushes}
                </TableCell>
                <TableCell align="center" className="cell">
                  {calculatePercentage(data.hits, data.misses, data.pushes)}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EntryStatistics;
