import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      // TODO: Clean up
      if (event.target) {
        if (event.target.id === "books") {
          let books = [];
          if (fields.plan === "ROOKIE") {
            books.push(event.target.value);
          } else {
            for (var i = 0; i < fields.books.length; i++) {
              books.push(fields.books[i]);
            }
            if (books.includes(event.target.value)) {
              const index = books.indexOf(event.target.value);
              books.splice(index, 1);
            } else {
              books.push(event.target.value);
            }
          }
          setValues({
            ...fields,
            books: books,
          });
        } else {
          setValues({
            ...fields,
            [event.target.id ? event.target.id : event.target.name]: event
              .target.value,
          });
        }
      } else {
        // TODO: Clean up
        setValues({
          email: event.email,
          books: event.books,
          notifications: event.notifications,
          phoneNumber: event.phoneNumber,
          oddsFormat: event.oddsFormat,
          plan: event.plan,
          username: event.username,
          userId: event.userId,
        });
      }
    },
  ];
}
