import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Toast = ({ open, duration, onClose, severity, text }) => {
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={duration} onClose={onClose}>
        <Alert onClose={onClose} severity={severity}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Toast;
