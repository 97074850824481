import firstGif from "assets/landingPage/01.gif";
import secondGif from "assets/landingPage/02.gif";
import thirdGif from "assets/landingPage/03.gif";

export const HOW_IT_WORKS = [
  {
    id: 1,
    heading: "Choose Your Bet",
    content:
      "Choose games from all major sporting events. Odds are pulled from the sports book you specify in your profile. We have odds from all major sportbooks.",
    image: firstGif,
  },
  {
    id: 2,
    heading: "Set Your Threshold",
    content:
      "Once a game is added to your Watch Slip you can set the threshold that will notify you on. This works for games that haven't started yet, as well as games that are live.",
    image: secondGif,
  },
  {
    id: 3,
    heading: "Get Texted",
    content:
      "Behind the scenes, we check every minute if the current odds of the game is better than the threshold you set. If it is, you will receive a notification, along with a link to your sports book for easy access.",
    image: thirdGif,
  },
  {
    id: 4,
    heading: "Win Your Bets!",
    content: "",
    image: "",
  },
];
export const FAQS = [
  {
    id: 1,
    question: "Is it completely free?",
    answer:
      "Yes! Signing up requires no credit card (seriously). If you want to get the best service, you can upgrade to any of the tiers.",
  },
  {
    id: 2,
    question: "How real-time is it?",
    answer:
      "Every minute our system will check the latest odds and notify you when applicable.",
  },
  {
    id: 3,
    question: "How often do alerts come in?",
    answer:
      "If the odds are better than the watch alert you set, we will send you a text message. We won't send more than one text per bet.",
  },
  {
    id: 4,
    question: "Can you add ______ sport/sport book?",
    answer:
      "We are continually adding in new sports and odds for you to enjoy. Please send an email or follow our Twitter for the latest updates.",
  },
];
