import React from "react";

export default [
  {
    title: "Welcome to OddsEye!",
    target: "body",
    placement: "center",
    content: (
      <div>
        <h4>Get notified when the odds improve...</h4>
        <h4>Win your bets!</h4>
      </div>
    ),
  },
  {
    title: "Toggle between various sports and leagues",
    target: ".sports-bar-2",
    content: (
      <div>
        <p>Sports that are not in-season will be disabled</p>
      </div>
    ),
  },
  {
    title: "Add odds to your watch slip",
    target: ".single-odds",
    placement: "top",
    content: (
      <div>
        <p>You can watch spreads, moneylines and totals</p>
      </div>
    ),
  },
  {
    title: "Navigation made easy",
    target: ".mobile-nav-entire",
    content: (
      <div>
        <p4>Navigate to the MyEye statistics page to view past results</p4>
      </div>
    ),
  },
];
