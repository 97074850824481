import React from "react";
import TextField from "@material-ui/core/TextField";
import "./UserProfile.scss";

const UserProfile = ({ fields, handleFieldChange, setHasChanged }) => {
  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <React.Fragment>
      <div className="icon-input-container-2">
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          onChange={(e) => {
            handleFieldChange({ ...fields, username: e.target.value });
            setHasChanged(true);
          }}
          error={fields.username && fields.username.length > 50}
          value={fields.username}
          className="user-profile"
          fullWidth
          inputProps={{ spellCheck: "false" }}
        />
      </div>
      <div className="icon-input-container-2">
        <TextField
          id="email"
          label="Email"
          type="email"
          variant="outlined"
          onChange={(e) => {
            handleFieldChange({ ...fields, email: e.target.value });
            setHasChanged(true);
          }}
          value={fields.email}
          error={fields.email && !isValidEmail(fields.email)}
          className="user-profile"
          disabled={true}
          fullWidth
        />
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
