import React, { useState } from "react";
import "./EntryHistory.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import Pagination from "../../components/Pagination/Pagination";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import bovada from "../../assets/bovada.png";
import fanduel from "../../assets/fanduel2.png";
import fivedimes from "../../assets/5dimes.png";
import mybookie from "../../assets/myBookie.png";
import pinnacle from "../../assets/pinnacle.png";
import draftkings from "../../assets/draftkings.png";

const EntryHistory = ({ activeSlipData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(5);

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = activeSlipData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  // const bookImage = (book) => {
  // if (book === "Bovada") {
  //   return (
  //     <img
  //       className="image"
  //       src={bovada}
  //       height={25}
  //       width={50}
  //       alt="bovada"
  //     />
  //   );
  // } else if (book === "Pinnacle") {
  //   return (
  //     <img
  //       className="image"
  //       src={pinnacle}
  //       height={25}
  //       width={50}
  //       alt="pinnacle"
  //     />
  //   );
  // } else if (book === "MyBookie") {
  //   return (
  //     <img
  //       className="image"
  //       src={mybookie}
  //       height={25}
  //       width={50}
  //       alt="mybookie"
  //     />
  //   );
  // } else if (book === "5Dimes") {
  //   return (
  //     <img
  //       className="image"
  //       src={fivedimes}
  //       height={25}
  //       width={50}
  //       alt="5dimes"
  //     />
  //   );
  // } else if (book === "FanDuel") {
  //   return (
  //     <img
  //       className="image"
  //       src={fanduel}
  //       height={25}
  //       width={50}
  //       alt="fanduel"
  //     />
  //   );
  // } else if (book === "DraftKings") {
  //   return (
  //     <img
  //       className="image"
  //       src={draftkings}
  //       height={25}
  //       width={50}
  //       alt="draftkings"
  //     />
  //   );
  // }
  // };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="root-history-content">
      <div className="alert-history-title">
        <h3>Watch Slip History</h3>
        <Tooltip
          title={
            <h4>
              Watch Slip history will only show games from the respective month
            </h4>
          }
          style={{ fontSize: "24px" }}
          placement="bottom"
        >
          <InfoIcon className="info-icon" />
        </Tooltip>
      </div>
      {/* {activeSlipData.length === 0 && <h5>Watch Slip History is Empty!</h5>} */}
      <TableContainer className="root">
        <Table className="table">
          <TableHead className="columns">
            <TableRow>
              <TableCell className="cell">Date</TableCell>
              <TableCell className="cell">Watch Bet Type</TableCell>
              <TableCell align="left" className="cell">
                Pick
              </TableCell>
              <TableCell align="center" className="cell">
                Watch Bet
              </TableCell>
              <TableCell align="center" className="cell">
                Result
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentEntries.map((data) => (
              <TableRow
                key={data.uniqueId}
                className={data.status ? data.status.toLowerCase() : ""}
              >
                <TableCell component="th" scope="row" className="cell">
                  <Moment format="MM/DD/YY h:mm A">{data.gameStartDate}</Moment>
                </TableCell>
                <TableCell align="left" className="cell">
                  {data.betType}
                </TableCell>
                <TableCell align="left" className="cell">
                  {data.pick}
                </TableCell>
                <TableCell align="center" className="cell">
                  {data.watchBet}{" "}
                  {data.watchBetPrice ? "(" + data.watchBetPrice + ")" : ""}
                </TableCell>
                <TableCell
                  align="center"
                  className={
                    data.status ? data.status.toLowerCase() + "-text" : ""
                  }
                >
                  {data.status.replace("_", " ")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {activeSlipData.length !== 0 && (
        <Pagination
          recordsPerPage={entriesPerPage}
          totalRecords={activeSlipData.length}
          paginate={paginate}
        />
      )}
    </div>
  );
};

export default EntryHistory;
