import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import Box from "@material-ui/core/Box";
import TopBar from "./containers/TopBar/TopBar";
import About from "./containers/About/About";
import Footer from "./components/Footer/Footer";
import { useCurrentWidth } from "./components/WidthCheck";
import MobileNav from "./containers/MobileNav/MobileNav";

const App = ({ history }) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const mobile = useCurrentWidth() <= 1000;
  const [cognitoUser, setCognitoUser] = useState("");
  const [showSlip, setShowSlip] = useState(mobile ? false : true);

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      await Auth.currentAuthenticatedUser().then((userData) => {
        setCognitoUser(userData);
      });
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  };

  const setAuthenticated = (authenticated) => {
    userHasAuthenticated(authenticated);
    Auth.currentAuthenticatedUser().then((userData) => {
      setCognitoUser(userData);
    });
  };

  const determineUrl = () => {
    const data = localStorage.getItem("sport-odds");
    if (data) {
      return data;
    }
    return "/sports";
  };

  const handleWatchSlipClick = (show) => {
    if (show === true) {
      setShowSlip(true);
    } else {
      setShowSlip(false);
    }
    if (!mobile) {
      setShowSlip(true);
    }
    // history.push(determineUrl());
  };

  const handleNavClick = (showWatchSlip) => {
    handleWatchSlipClick(showWatchSlip);
    history.push(determineUrl());
  };

  // TODO: add in loader screen for isAuthenticating
  return (
    !isAuthenticating && (
      <div className="App">
        {isAuthenticated ? (
          <div>
            <TopBar
              history={history}
              setAuthenticated={setAuthenticated}
              cognitoUser={cognitoUser}
              mobile={mobile}
            />
            <Box flex={1}>
              <Routes
                appProps={{
                  isAuthenticated,
                  userHasAuthenticated,
                  mobile,
                  cognitoUser,
                  showSlip,
                  history,
                  handleWatchSlipClick,
                  setAuthenticated,
                }}
              />
            </Box>
          </div>
        ) : (
          <About
            history={history}
            setAuthenticated={setAuthenticated}
            mobile={mobile}
          />
        )}
        {!mobile && <Footer />}
        {isAuthenticated && mobile && (
          <MobileNav
            mobile={mobile}
            cognitoUser={cognitoUser}
            handleNavClick={handleNavClick}
          />
        )}
      </div>
    )
  );
};

export default withRouter(App);
