import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "./TopBar.scss";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import UserIcon from "../UserIcon/UserIcon";
import { Auth } from "aws-amplify";
import oddseye from "../../assets/oddseye2.png";

const TopBar = ({ setAuthenticated, history, cognitoUser, mobile }) => {
  const [value, setValue] = useState("");
  const path = history.location.pathname;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLogout = async () => {
    await Auth.signOut();
    setAuthenticated(false);
    history.push("/");
  };

  const handleProfile = () => {
    history.push("/profile");
  };

  const handleSettings = () => {
    history.push("/settings");
  };

  // TODO: Fade in and out on transition
  const determineTabValue = () => {
    if (path.includes("/sports") || path === "/") {
      return 0;
    } else if (path === "/entry") {
      return 1;
    } else if (path === "/pricing") {
      return 2;
    } else {
      return "";
    }
  };

  const determineUrl = () => {
    const data = localStorage.getItem("sport-odds");
    if (data) {
      return data;
    }
    return "/sports/baseball/mlb";
  };

  return (
    <div id="top-bar">
      <Paper className="TopBar">
        <Button disableRipple className="logo">
          <Link className="link" to="/">
            <img src={oddseye} height={30} width={150} alt="oddseye" />
          </Link>
        </Button>
        <UserIcon
          logout={handleLogout}
          profile={handleProfile}
          settings={handleSettings}
          cognitoUser={cognitoUser}
          mobile={mobile}
        />
        {!mobile && (
          <Tabs
            value={determineTabValue()}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className="tabs"
          >
            <Tab
              disableRipple
              label="Sports"
              component={Link}
              to={determineUrl}
              className="tabText"
            />
            <Tab
              disableRipple
              label="My Eye"
              component={Link}
              to="/entry"
              className="tabText"
            />
            <Tab
              disableRipple
              label="Subscription"
              component={Link}
              to="/pricing"
              className="tabText"
            />
          </Tabs>
        )}
      </Paper>
    </div>
  );
};

export default TopBar;
