import React, { useState } from "react";
import Moment from "react-moment";
import "./ActiveWatchSlipEntries.scss";
import Spinner from "../../components/Spinner/Spinner";
import Pagination from "../../components/Pagination/Pagination";
import emptySlip from "assets/emptyWatchSlip.png";

const ActiveWatchSlipEntries = ({ activeSlipData, isLoading }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(5);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = activeSlipData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  if (isLoading) {
    return (
      <div className="activeWatchSlipLoading">
        <Spinner size={60} />
      </div>
    );
  }
  if (!isLoading && activeSlipData.length === 0) {
    return (
      <div className="emptyWatchSlip">
        <img src={emptySlip} alt="emptySlip" />
        <h6>No active watch slip entries. Add any game to get started!</h6>
      </div>
    );
  }
  return (
    <div>
      {currentEntries.map((activeSlipData) => (
        <div key={activeSlipData.uniqueId}>
          <h5 className="betPicked">
            {activeSlipData.pick.toLowerCase()} {activeSlipData.watchBet}{" "}
            {activeSlipData.watchBetPrice
              ? "(" + activeSlipData.watchBetPrice + ")"
              : ""}
          </h5>
          <div className="line"></div>
          <h5 className="betType">{activeSlipData.betType}</h5>
          <h6>
            <Moment format="MM/DD/YY h:mm A">
              {activeSlipData.gameStartDate}
            </Moment>
          </h6>
          <h6 className="betType">
            {activeSlipData.awayTeam.toLowerCase()} {" @ "}
            {activeSlipData.homeTeam.toLowerCase()}
          </h6>
          <br />
          <hr />
        </div>
      ))}
      <div className="pagination">
        <Pagination
          recordsPerPage={entriesPerPage}
          totalRecords={activeSlipData.length}
          paginate={paginate}
        />
      </div>
    </div>
  );
};

export default ActiveWatchSlipEntries;
