import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  errorText: {
    position: "absolute",
    bottom: -20,
    fontSize: 12,
  },
});

export default function FieldWithValidation({ children, error }) {
  const classes = useStyles();
  return (
    <FormControl fullWidth error={!!error}>
      {children}
      <FormHelperText error className={classes.errorText}>
        {error}
      </FormHelperText>
    </FormControl>
  );
}
