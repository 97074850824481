import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import NotFound from "./containers/NotFound/NotFound";
import AppliedRoute from "./components/AppliedRoute/AppliedRoute";
import Odds from "./containers/Odds/Odds";
import SportsBar from "./containers/SportsBar/SportsBar";
import Questions from "./containers/Questions/Questions";
import Entry from "./containers/Entry/Entry";
import Settings from "./containers/Settings/Settings";
import Pricing from "./containers/Pricing/Pricing";
import ScrollToTop from "./hooks/scrollToTop";

const Routes = ({ appProps }) => {
  return (
    <div>
      <ScrollToTop />
      <Switch>
        <AppliedRoute
          path="/"
          exact
          component={withRouter(Odds)}
          appProps={appProps}
        />
        <AppliedRoute
          path="/sports/*"
          exact
          component={withRouter(Odds)}
          appProps={appProps}
        />
        <AppliedRoute
          path="/sports"
          exact
          component={SportsBar}
          appProps={appProps}
        />
        <AppliedRoute
          path="/entry"
          exact
          component={Entry}
          appProps={appProps}
        />
        <AppliedRoute
          path="/faq"
          exact
          component={Questions}
          appProps={appProps}
        />
        <AppliedRoute
          path="/settings"
          exact
          component={Settings}
          appProps={appProps}
        />
        <AppliedRoute
          path="/pricing"
          exact
          component={Pricing}
          appProps={appProps}
        />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
