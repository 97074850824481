import React from "react";
import "./Sportsbook.scss";

const Sportsbook = ({
  fields,
  handleFieldChange,
  dailyAccess,
  setHasChanged,
}) => {
  const isChecked = (book) => {
    for (var i = 0; i < fields.books.length; i++) {
      if (fields.books[i] === book) {
        return true;
      }
    }
    return false;
  };
  return (
    <div className="container">
      <div className="radio-buttons">
        <label>
          <input
            id="books"
            type={dailyAccess.maxBooks > 1 ? "checkbox" : "radio"}
            checked={isChecked("DraftKings")}
            onChange={(e) => {
              handleFieldChange(e);
              setHasChanged(true);
            }}
            value="DraftKings"
          />
          <h4>DraftKings</h4>
        </label>
        <label>
          <input
            id="books"
            type={dailyAccess.maxBooks > 1 ? "checkbox" : "radio"}
            checked={isChecked("Barstool")}
            onChange={(e) => {
              handleFieldChange(e);
              setHasChanged(true);
            }}
            value="Barstool"
          />
          <h4>Barstool</h4>
        </label>
      </div>
      <div className="radio-buttons" style={{ alignItems: "flex-start" }}>
        <label>
          <input
            id="books"
            type={dailyAccess.maxBooks > 1 ? "checkbox" : "radio"}
            checked={isChecked("FanDuel")}
            onChange={(e) => {
              handleFieldChange(e);
              setHasChanged(true);
            }}
            value="FanDuel"
          />
          <h4>FanDuel</h4>
        </label>
        <label>
          <input
            id="books"
            type={dailyAccess.maxBooks > 1 ? "checkbox" : "radio"}
            checked={isChecked("Bovada")}
            onChange={(e) => {
              handleFieldChange(e);
              setHasChanged(true);
            }}
            value="Bovada"
          />
          <h4>Bovada</h4>
        </label>
      </div>
    </div>
  );
};

export default Sportsbook;
