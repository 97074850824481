import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useFormFields } from "../../shared/hooks/hooksLib";
import { Auth } from "aws-amplify";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "./SignUpOverlay.scss";

const SignUpOverlay = ({ isSignUp, handleSignUpSuccessfully, handleClose }) => {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    phoneNumber: "",
    name: "",
    book: "",
    password: "",
    username: "",
    confirmationCode: "",
  });
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeResent, setIsCodeResent] = useState(false);

  const validateForm = () => {
    return (
      fields.username &&
      fields.username.length > 0 &&
      fields.username.length < 50 &&
      fields.phoneNumber &&
      fields.phoneNumber.length > 0 &&
      isValidPhoneNumber(fields.phoneNumber) &&
      fields.email &&
      fields.email.length > 0 &&
      isValidEmail(fields.email) &&
      fields.book &&
      fields.book.length > 0 &&
      fields.password &&
      fields.password.length > 0 &&
      fields.password.length < 75 &&
      isValidPassword(fields.password)
    );
  };

  const validateConfirmationForm = () => {
    return fields.confirmationCode && fields.confirmationCode.length > 0;
  };

  const cleanPhoneNumber = (phoneNumber) => {
    // strip out non-numbers
    phoneNumber = phoneNumber.replace(/\D/g, "");
    if (phoneNumber.length === 10) {
      return "+1" + phoneNumber;
    } else if (phoneNumber.length === 11) {
      return "+" + phoneNumber;
    }
    return phoneNumber;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const phoneNumber = cleanPhoneNumber(fields.phoneNumber);
    setIsLoading(true);
    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          email: fields.email,
          phone_number: phoneNumber,
          "custom:book": fields.book,
          preferred_username: fields.username,
        },
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      alert("Error occurred while signing up. Please try again.");
      setIsLoading(false);
    }
  };

  const handleResendClick = async (event) => {
    event.preventDefault();
    try {
      await Auth.resendSignUp(fields.email);
      setIsCodeResent(true);
    } catch (e) {
      alert("Error occurred while resending code. Please try again.");
    }
  };

  const handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
      handleSignUpSuccessfully();
    } catch (e) {
      alert("Error confirming code. Please try again later.");
      setIsLoading(false);
    }
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isValidPassword = (password) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    // const re = /^[0-9]*$/;
    const re = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    return re.test(phoneNumber);
  };

  const renderConfirmationForm = () => {
    return (
      <div>
        <Dialog
          open={isSignUp}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          className="login-dialog"
          maxWidth={"sm"}
        >
          <DialogTitle className="dialog-title" id="form-dialog-title">
            Confirmation Form
          </DialogTitle>
          <DialogContent>
            <div className="icon-input-container">
              <DialogContentText>
                Please check your phone for your confirmation code
              </DialogContentText>
              <TextField
                autoFocus
                id="confirmationCode"
                label="Code"
                variant="outlined"
                type="tel"
                onChange={handleFieldChange}
                value={fields.confirmationCode}
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleResendClick}
              color="primary"
              disabled={isLoading || isCodeResent}
              className="login-button"
            >
              Resend
            </Button>
            <Button
              onClick={handleClose}
              color="primary"
              disabled={isLoading}
              className="login-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmationSubmit}
              disabled={!validateConfirmationForm() || isLoading}
              color="primary"
              className="login-button"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div>
        <Dialog
          open={isSignUp}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
        >
          <DialogTitle className="dialog-title" id="form-dialog-title">
            Sign Up!
            <Button disableRipple onClick={handleClose} className="xButton">
              X
            </Button>
          </DialogTitle>

          <DialogContent>
            <div className="icon-input-container">
              <TextField
                autoFocus
                id="username"
                variant="outlined"
                label="Username"
                onChange={handleFieldChange}
                value={fields.username}
                fullWidth
                inputProps={{ spellCheck: "false" }}
              />
            </div>
            <div className="icon-input-container">
              <TextField
                id="phoneNumber"
                type="tel"
                variant="outlined"
                label="Phone Number"
                error={
                  fields.phoneNumber && !isValidPhoneNumber(fields.phoneNumber)
                }
                onChange={handleFieldChange}
                value={fields.phoneNumber}
                fullWidth
              />
            </div>
            <div className="icon-input-container">
              <TextField
                id="email"
                type="email"
                variant="outlined"
                label="Email"
                onChange={handleFieldChange}
                value={fields.email}
                error={fields.email && !isValidEmail(fields.email)}
                fullWidth
              />
            </div>
            <div className="icon-input-container">
              <FormControl variant="outlined" className="select-dialog-field">
                <InputLabel>Book</InputLabel>
                <Select
                  value={fields.book}
                  id="book"
                  variant="outlined"
                  name="book"
                  onChange={handleFieldChange}
                  label="Book"
                  fullWidth
                >
                  <MenuItem value={""}>--Select One --</MenuItem>
                  <MenuItem value={"Barstool"}>Barstool</MenuItem>
                  <MenuItem value={"Bovada"}>Bovada</MenuItem>
                  <MenuItem value={"DraftKings"}>DraftKings</MenuItem>
                  <MenuItem value={"FanDuel"}>FanDuel</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="icon-input-container">
              <TextField
                id="password"
                type="password"
                variant="outlined"
                label="Password"
                onChange={handleFieldChange}
                value={fields.password}
                error={fields.password && !isValidPassword(fields.password)}
                helperText={
                  "Min. of 8 characters, 1 lowercase, 1 uppercase and 1 number."
                }
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              disabled={isLoading}
              className="login-button"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={!validateForm() || isLoading}
              color="primary"
              className="login-button"
            >
              Sign up
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <div>{newUser === null ? renderForm() : renderConfirmationForm()}</div>
  );
};

export default SignUpOverlay;
