import { API } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { useAsyncCallback } from "react-async-hook";
import Moment from "react-moment";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import WatchSlip from "containers/WatchSlip/WatchSlip";
import SportsBar from "containers/SportsBar/SportsBar";
import Spinner from "components/Spinner/Spinner";
import { useWatchSlipContext } from "contexts/watchSlip";
import OnboardingSteps from "./OnboardingSteps";

import bovada from "assets/bovada.png";
import draftkings from "assets/draftkings.png";
import pinnacle from "assets/pinnacle.png";
import fanduel from "assets/fanduel2.png";
import mybookie from "assets/myBookie.png";
import fivedimes from "assets/5dimes.png";
import nba from "assets/nba.png";
import ncaa from "assets/ncaa.png";
import nfl from "assets/nfl.png";

import "./Odds.scss";

const BOOKS_ASSETS = {
  Bovada: "Bovada",
  Pinnacle: "Pinnacle",
  MyBookie: "MyBookie",
  "5Dimes": "5Dimes",
  FanDuel: "FanDuel",
  DraftKings: "DraftKings",
};

const SPORTS_ASSETS = {
  nba,
  ncaa: ncaa,
  nfl,
};

const Odds = ({
  mobile,
  match,
  cognitoUser,
  isAuthenticated,
  showSlip,
  history,
  handleWatchSlipClick,
}) => {
  const { updateMobileNavWatchSlipCount } = useWatchSlipContext();
  const [data, setData] = useState([]);
  const [hidingSlip, setHidingSlip] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [watchSlipAdded, setWatchSlipAdded] = useState([]);
  const [timeout, setTimeout] = useState(false);
  const [isError, setIsError] = useState(false);
  const [user, setUser] = useState([]);
  const [bookViewing, setBookViewing] = useState("");
  const path =
    Object.keys(match.params).length !== 0
      ? match.params[0].split("/")
      : ["featured", "featured"];
  const [sport, setSport] = useState(path[0]);
  const [league, setLeague] = useState(path[1]);
  let nextEvents = league.toUpperCase();
  if (league === "ncaa") {
    nextEvents += sport.charAt(0).toUpperCase();
    nextEvents += " - Next Events";
  } else if (league == "featured") {
    nextEvents = "Featured Game of the Day";
  } else {
    nextEvents += " - Next Events";
  }

  const oddsParams = {
    queryStringParameters: {
      sport: sport,
      book: bookViewing,
      league: league,
    },
  };

  const onClick = (
    betType,
    oddsData,
    pick,
    currentBetPrice,
    currentBet,
    id
  ) => {
    const clicked = isButtonClicked(id, betType, pick, true);
    if (!clicked) {
      let plusOrMinus = "plus";
      if (currentBet < 0) {
        plusOrMinus = "minus";
      }
      const plusOrMinusPrice = "plus";
      const data = {
        betType,
        ...oddsData,
        pick,
        currentBetPrice,
        currentBet,
        plusOrMinus,
        plusOrMinusPrice,
      };
      setWatchSlipAdded([...watchSlipAdded, data]);
      const colorData = {
        id,
        betType,
        pick,
      };
    }
  };

  const userParams = {
    queryStringParameters: {
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const fetchUser = useAsyncCallback(async () => {
    await API.get("user", "/user", userParams)
      .then((res) => {
        setUser(res);
        setIsError(false);
        let data = localStorage.getItem("book");
        if (data) {
          setBookViewing(data);
        } else {
          setBookViewing(res.books[0]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  });

  const mergeById = (a1, a2) =>
    a1.map((itm) => ({
      ...a2.find((item) => item.gameUID === itm.gameUID && item),
      ...itm,
    }));

  const fetchOddsAndScores = useAsyncCallback(async (book, league) => {
    setData([]);
    if (book) {
      oddsParams.queryStringParameters.book = book;
    }
    if (league) {
      oddsParams.queryStringParameters.league = league;
    }
    Promise.all([
      API.get("odds", "/sports/odds", oddsParams),
      API.get("liveScores", "/live-scores", oddsParams),
    ])
      .then((res) => {
        setData(mergeById(res[0], res[1]));
        setIsLoading(false);
        setIsError(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  });

  const initializeWatchSlip = () => {
    setWatchSlipAdded([]);
  };

  const formatDateTime = (myDate) => {
    var momentDate = moment(myDate).format("MM/DD/YY");
    return moment(myDate).calendar(null, {
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "[" + momentDate + "]",
      sameElse: function () {
        return "[" + momentDate + "]";
      },
    });
  };

  const updateWatchSlips = (data) => {
    setWatchSlipAdded(data);
    localStorage.setItem("watch-slip", JSON.stringify(watchSlipAdded));
  };

  const onRemoveWatchSlipEntry = (index) => {
    setWatchSlipAdded(watchSlipAdded.filter((e, i) => i !== index));
  };

  useEffect(() => {
    const data = localStorage.getItem("watch-slip");
    if (data) {
      setWatchSlipAdded(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("watch-slip", JSON.stringify(watchSlipAdded));
  });

  const isButtonClicked = (id, type, pick, remove) => {
    for (var i = 0; i < watchSlipAdded.length; i++) {
      if (
        watchSlipAdded[i].id === id &&
        watchSlipAdded[i].betType === type &&
        watchSlipAdded[i].pick === pick
      ) {
        if (remove) {
          onRemoveWatchSlipEntry(i);
        }
        return true;
      }
    }
    return false;
  };

  const determineDisabled = (value) => {
    return false;
  };

  const formatPeriod = (period, sport, league) => {
    if (!period) {
      return "";
    }
    // baseball needs to be parsed separately
    let prefix = "";
    if (sport == "Baseball") {
      let temp = period.split(" ");
      prefix = temp[0] + " ";
      period = temp[1];
    }
    let numberFormat = "";
    var j = period % 10,
      k = period % 100;
    if (j == 1 && k != 11) {
      numberFormat = period + "st";
    } else if (j == 2 && k != 12) {
      numberFormat = period + "nd";
    } else if (j == 3 && k != 13) {
      numberFormat = period + "rd";
    } else {
      numberFormat = period + "th";
    }
    let periodType = "";
    if (sport === "Basketball" || sport === "Football") {
      if (league === "NBA") {
        periodType = "Quarter";
      } else {
        periodType = "Half";
      }
    } else if (sport === "Hockey") {
      periodType = "Period";
    }
    return prefix + numberFormat + " " + periodType;
  };

  const renderLeagues = () => {
    let leagueOptions = [];
    if (sport === "basketball") {
      leagueOptions = ["nba", "ncaa"];
    } else if (sport === "football") {
      leagueOptions = ["nfl", "ncaa"];
    }

    return leagueOptions.map((leagueOption, index) => (
      <ToggleButton
        key={index}
        value={leagueOption}
        disabled={determineDisabled(leagueOption)}
        className="toggle-button"
      >
        <h6>{leagueOption}</h6>
        {/* <img
          className="image"
          src={SPORTS_ASSETS[leagueOption]}
          height={25}
          width={50}
          alt={leagueOption}
        /> */}
      </ToggleButton>
    ));
  };

  useEffect(() => {
    if (fetchOddsAndScores.status === "not-requested" && bookViewing !== "") {
      fetchOddsAndScores.execute();
    }
    if (fetchUser.status === "not-requested") {
      fetchUser.execute();
    }
    // if (fetchScores.status === "not-requested" && bookViewing !== "") {
    //   fetchScores.execute();
    // }
  }, [fetchOddsAndScores, data, bookViewing, fetchUser]);

  useEffect(() => {
    updateMobileNavWatchSlipCount(watchSlipAdded.length);
  }, [watchSlipAdded, updateMobileNavWatchSlipCount]);

  return (
    <div id="sports-page-cont">
      <SportsBar
        isAuthenticated={isAuthenticated}
        history={history}
        showSlip={showSlip}
        mobile={mobile}
      />

      <div id="sports-page">
        {history.location.search.includes("isNewUser=true") && (
          <OnboardingSteps mobile={mobile} history={history} />
        )}
        {showSlip || !mobile ? (
          <div>
            <WatchSlip
              watchSlipData={watchSlipAdded}
              initializeWatchSlip={initializeWatchSlip}
              onRemoveWatchSlipEntry={onRemoveWatchSlipEntry}
              hidingSlip={hidingSlip}
              mobile={mobile}
              cognitoUser={cognitoUser}
              updateWatchSlips={updateWatchSlips}
              handleWatchSlipClick={handleWatchSlipClick}
              history={history}
            />
          </div>
        ) : (
          ""
        )}
        <div id="single-odds-cont">
          <h3 className="nextEvents">{nextEvents}</h3>
          <div className="nextEvents books">
            {user.books && (
              <Grid container justify="center" alignItems="center">
                <Box py={2}>
                  {(sport === "basketball" || sport === "football") && (
                    <Grid item>
                      <ToggleButtonGroup
                        size="small"
                        value={league}
                        exclusive
                        className="toggle-button-books-group"
                        onChange={(e, value) => {
                          if (value !== null) {
                            setIsLoading(true);
                            history.push(`/sports/${sport}/${value}`);
                            fetchOddsAndScores.execute(bookViewing, value);
                            setLeague(value);
                            localStorage.setItem("league", value);
                          } else {
                            setIsLoading(true);
                            fetchOddsAndScores.execute();
                          }
                        }}
                      >
                        {renderLeagues()}
                      </ToggleButtonGroup>
                    </Grid>
                  )}
                  <Grid item>
                    <ToggleButtonGroup
                      size="small"
                      value={bookViewing}
                      className="toggle-button-books-group"
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) {
                          setIsLoading(true);
                          fetchOddsAndScores.execute(value);
                          setBookViewing(value);
                          localStorage.setItem("book", value);
                        } else {
                          setIsLoading(true);
                          fetchOddsAndScores.execute();
                        }
                      }}
                    >
                      {user.books.map((book, index) => (
                        <ToggleButton
                          key={index}
                          value={book}
                          className="toggle-button"
                        >
                          {/* <img
                            className="image"
                            src={BOOKS_ASSETS[book]}
                            height={25}
                            width={50}
                            alt={book}
                          /> */}
                          <h6 style={{ textTransform: "none" }}>{book}</h6>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                </Box>
              </Grid>
            )}

            {isError && <h4>Woops! There was an error. Please try again.</h4>}
            {!isLoading && !isError && data.length === 0 && (
              <h4>There are currently no odds for this request</h4>
            )}
          </div>
          {isLoading && (
            <div className="oddsLoading">
              <Spinner size={60} />
            </div>
          )}
          {data.map((oddsData, i) => (
            <React.Fragment key={oddsData.id}>
              <div className="single-odds">
                <div className="teams-cont">
                  <div className="team-name-and-score">
                    <h3>{oddsData.awayTeam}</h3>
                    {oddsData.isLive === "1" &&
                      oddsData.scoreAwayTotal &&
                      sport !== "mixed-martial-arts" && (
                        <div className="odds-score">
                          {oddsData.scoreAwayTotal}
                        </div>
                      )}
                  </div>
                  <div className="team-name-and-score">
                    <h3>{oddsData.homeTeam}</h3>
                    {oddsData.isLive === "1" &&
                      oddsData.scoreHomeTotal &&
                      sport !== "mixed-martial-arts" && (
                        <div className="odds-score">
                          {oddsData.scoreHomeTotal}
                        </div>
                      )}
                  </div>
                </div>
                <div className="odds-buttons-cont">
                  {i === 0 && (
                    <div className="header">
                      {sport !== "mixed-martial-arts" && <h6>Spread</h6>}
                      <h6>ML</h6>
                      {sport !== "mixed-martial-arts" && <h6>Total</h6>}
                    </div>
                  )}
                  <div className="team-odds">
                    {sport !== "mixed-martial-arts" && (
                      <button
                        onClick={() =>
                          onClick(
                            "Spread",
                            oddsData,
                            oddsData.awayTeam,
                            oddsData.gameSpreadAwayPrice,
                            oddsData.gameSpreadAwayHandicap,
                            oddsData.id
                          )
                        }
                        variant="contained"
                        className={
                          isButtonClicked(
                            oddsData.id,
                            "Spread",
                            oddsData.awayTeam
                          )
                            ? "buttonOddsClicked"
                            : "buttonOddsNotClicked"
                        }
                        disabled={!oddsData.gameSpreadAwayHandicap}
                      >
                        {oddsData.gameSpreadAwayHandicap &&
                        oddsData.gameSpreadAwayPrice
                          ? oddsData.gameSpreadAwayHandicap +
                            " (" +
                            oddsData.gameSpreadAwayPrice +
                            ")"
                          : "―"}
                      </button>
                    )}
                    <button
                      onClick={() =>
                        onClick(
                          "Moneyline",
                          oddsData,
                          oddsData.awayTeam,
                          null,
                          oddsData.gameMoneylineAwayPrice,
                          oddsData.id
                        )
                      }
                      variant="contained"
                      className={
                        isButtonClicked(
                          oddsData.id,
                          "Moneyline",
                          oddsData.awayTeam
                        )
                          ? "buttonOddsClicked"
                          : "buttonOddsNotClicked"
                      }
                      disabled={!oddsData.gameMoneylineAwayPrice}
                    >
                      {oddsData.gameMoneylineAwayPrice
                        ? oddsData.gameMoneylineAwayPrice
                        : "―"}
                    </button>
                    {sport !== "mixed-martial-arts" && (
                      <button
                        onClick={() =>
                          onClick(
                            "Total",
                            oddsData,
                            "Over",
                            oddsData.gameTotalOverPrice,
                            oddsData.gameTotalPoints,
                            oddsData.id
                          )
                        }
                        variant="contained"
                        className={
                          isButtonClicked(oddsData.id, "Total", "Over")
                            ? "buttonOddsClicked"
                            : "buttonOddsNotClicked"
                        }
                        disabled={!oddsData.gameTotalPoints}
                      >
                        {oddsData.gameTotalPoints && oddsData.gameTotalOverPrice
                          ? "o" +
                            oddsData.gameTotalPoints +
                            " (" +
                            oddsData.gameTotalOverPrice +
                            ")"
                          : "—"}
                      </button>
                    )}
                  </div>
                  <div className="team-odds">
                    {sport !== "mixed-martial-arts" && (
                      <button
                        onClick={(e) =>
                          onClick(
                            "Spread",
                            oddsData,
                            oddsData.homeTeam,
                            oddsData.gameSpreadHomePrice,
                            oddsData.gameSpreadHomeHandicap,
                            oddsData.id,
                            e
                          )
                        }
                        className={
                          isButtonClicked(
                            oddsData.id,
                            "Spread",
                            oddsData.homeTeam
                          )
                            ? "buttonOddsClicked"
                            : "buttonOddsNotClicked"
                        }
                        disabled={!oddsData.gameSpreadHomeHandicap}
                      >
                        {oddsData.gameSpreadHomeHandicap &&
                        oddsData.gameSpreadHomePrice
                          ? oddsData.gameSpreadHomeHandicap +
                            " (" +
                            oddsData.gameSpreadHomePrice +
                            ")"
                          : "―"}
                      </button>
                    )}
                    <button
                      onClick={() =>
                        onClick(
                          "Moneyline",
                          oddsData,
                          oddsData.homeTeam,
                          null,
                          oddsData.gameMoneylineHomePrice,
                          oddsData.id
                        )
                      }
                      className={
                        isButtonClicked(
                          oddsData.id,
                          "Moneyline",
                          oddsData.homeTeam
                        )
                          ? "buttonOddsClicked"
                          : "buttonOddsNotClicked"
                      }
                      disabled={!oddsData.gameMoneylineHomePrice}
                    >
                      {oddsData.gameMoneylineHomePrice
                        ? oddsData.gameMoneylineHomePrice
                        : "―"}
                    </button>
                    {sport !== "mixed-martial-arts" && (
                      <button
                        onClick={() =>
                          onClick(
                            "Total",
                            oddsData,
                            "Under",
                            oddsData.gameTotalUnderPrice,
                            oddsData.gameTotalPoints,
                            oddsData.id
                          )
                        }
                        className={
                          isButtonClicked(oddsData.id, "Total", "Under")
                            ? "buttonOddsClicked"
                            : "buttonOddsNotClicked"
                        }
                        disabled={!oddsData.gameTotalPoints}
                      >
                        {oddsData.gameTotalPoints &&
                        oddsData.gameTotalUnderPrice
                          ? "u" +
                            oddsData.gameTotalPoints +
                            " (" +
                            oddsData.gameTotalUnderPrice +
                            ")"
                          : "―"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="more-bets-2">
                {oddsData.isLive === "1" && sport !== "mixed-martial-arts" ? (
                  <div className="odds-time-live">
                    <div className="odds-status">
                      <h4>{oddsData.status ? oddsData.status : "LIVE"}</h4>
                    </div>
                    {formatPeriod(
                      oddsData.currentPeriod,
                      oddsData.sport,
                      oddsData.league
                    )}
                    {oddsData.currentPeriodTimeRemaining
                      ? " " + oddsData.currentPeriodTimeRemaining
                      : ""}
                  </div>
                ) : (
                  <div className="odds-time-live">
                    <div className="odds-date">
                      {formatDateTime(oddsData.startDate)}
                    </div>
                    <div className="odds-time">
                      <Moment format="h:mm A">{oddsData.startDate}</Moment>
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="hack"></div>
    </div>
  );
};

export default Odds;
