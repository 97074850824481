import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { useAsyncCallback } from "react-async-hook";
import { API } from "aws-amplify";
import Spinner from "../../../components/Spinner/Spinner";
import "./Feedback.scss";

const Feedback = ({ userId }) => {
  const [feedback, setFeedback] = useState("");
  const [category, setCategory] = useState("");
  const [isSending, setIsSending] = useState(false);

  const onSubmitClick = () => {
    setIsSending(true);
    let data = {
      feedback: feedback,
      category: category,
      userId: userId,
    };
    submitFeedback.execute(data);
  };

  const submitFeedback = useAsyncCallback(async (data) => {
    const myInit = {
      body: data,
    };
    await API.post("feedback", "/feedback", myInit)
      .then((res) => {
        setIsSending(false);
        setFeedback("");
        setCategory("");
      })
      .catch(() => {
        setIsSending(false);
        setFeedback("");
        setCategory("");
      });
  });

  const isSubmitDisabled = () => {
    return category === "" || feedback === "";
  };
  return (
    <div>
      <div className="icon-input-container-2">
        <FormControl
          variant="outlined"
          className="select-dialog-field-2"
          fullWidth
        >
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            id="category"
            variant="outlined"
            name="category"
            onChange={(e) => {
              setCategory(e.target.value);
            }}
            label="Category"
            fullWidth
          >
            <MenuItem value={""}>--Select One --</MenuItem>
            <MenuItem value={"Question"}>Question</MenuItem>
            <MenuItem value={"Request"}>Request</MenuItem>
            <MenuItem value={"Complaint"}>Complaint</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TextField
        id="text"
        onChange={(e) => {
          setFeedback(e.target.value);
        }}
        label="Feedback"
        variant="outlined"
        value={feedback}
        multiline={true}
        inputProps={{ maxLength: 750 }}
        className="feedbackText"
        fullWidth
      />
      <Button
        className="submit-button"
        style={{ float: "right" }}
        variant="contained"
        color="primary"
        onClick={onSubmitClick}
        disabled={isSubmitDisabled()}
      >
        {isSending && <Spinner className="spinner" size={20} />}
        Send Feedback
      </Button>
    </div>
  );
};

export default Feedback;
