import React, { useState, useEffect } from "react";
import "./Settings.scss";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MoneyIcon from "@material-ui/icons/Money";
import NotificationsIcon from "@material-ui/icons/Notifications";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Auth } from "aws-amplify";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import StarsIcon from "@material-ui/icons/Stars";
import { API } from "aws-amplify";
import { useAsyncCallback } from "react-async-hook";
import UserProfile from "./Sections/UserProfile";
import Sportsbook from "./Sections/Sportsbook";
import Notifications from "./Sections/Notifications";
import Spinner from "../../components/Spinner/Spinner";
import { useFormFields } from "../../shared/hooks/hooksLib";
import BetSettings from "./Sections/BetSettings";
import Feedback from "./Sections/Feedback";
import Toast from "../../components/Toast/Toast";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";

const Settings = ({ cognitoUser, history, setAuthenticated }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fields, handleFieldChange] = useFormFields({});
  const [dropdown, setDropdown] = useState(null);
  const [dailyAccess, setDailyAccess] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const params = {
    queryStringParameters: {
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const accessParams = {
    queryStringParameters: {
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const editUser = useAsyncCallback(async (data) => {
    const myInit = {
      body: data,
    };
    await API.post("user", "/user", myInit)
      .then((res) => {
        setIsSuccess(true);
        setIsError(false);
        setHasChanged(false);
        setIsSaving(false);
      })
      .catch(() => {
        setIsError(true);
        setIsSuccess(false);
        setIsSaving(false);
      });
  });

  const onSubmitClick = () => {
    setIsSaving(true);
    editUser.execute(fields);
    localStorage.removeItem("book");
  };

  const onSubscriptionClick = () => {
    history.push("/pricing");
  };

  const onLogoutClick = async () => {
    await Auth.signOut();
    setAuthenticated(false);
    history.push("/");
  };

  const fetchUserData = useAsyncCallback(async () => {
    const user = await API.get("user", "/user", params);
    setIsLoading(false);
    handleFieldChange(user);
  });

  useEffect(() => {
    if (fetchUserData.status === "not-requested") {
      fetchUserData.execute();
    }
  }, [fetchUserData]);

  const fetchDailyAccess = useAsyncCallback(async () => {
    await API.get("dailyAccess", "/dailyAccess", accessParams).then((res) => {
      setDailyAccess(res);
    });
  });

  const handleOnClose = () => {
    setIsSuccess(false);
    setIsError(false);
  };

  const isValidEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const isSubmitDisabled = () => {
    return (
      !fields.email ||
      fields.email === "" ||
      !isValidEmail(fields.email) ||
      !fields.username ||
      fields.username === "" ||
      fields.username.length > 50 ||
      !fields.books ||
      fields.books.length === 0 ||
      !hasChanged
    );
  };

  useEffect(() => {
    if (fetchDailyAccess.status === "not-requested") {
      fetchDailyAccess.execute();
    }
  }, [fetchDailyAccess, dailyAccess]);

  return (
    <div className="settings-page">
      {isLoading ? (
        <Spinner size={60} />
      ) : (
        <div className="settings-page">
          <div className="settings-cont">
            {isSuccess && (
              <Toast
                open={isSuccess}
                duration={3000}
                onClose={handleOnClose}
                severity="success"
                text="Settings Saved!"
              />
            )}
            {isError && (
              <Toast
                open={isError}
                duration={3000}
                onClose={handleOnClose}
                severity="error"
                text="Woops! Please try again."
              />
            )}
            <div className="settings-item">
              <div
                className="settings-item-header"
                onClick={() =>
                  dropdown === "userProfile"
                    ? setDropdown(null)
                    : setDropdown("userProfile")
                }
              >
                <div className="header-left">
                  <AccountBoxIcon className="icon" />
                  Edit User Profile
                </div>
                <div className="header-right">
                  <ArrowForwardIosIcon />
                </div>
              </div>
              {dropdown === "userProfile" && (
                <UserProfile
                  fields={fields}
                  handleFieldChange={handleFieldChange}
                  setHasChanged={setHasChanged}
                />
              )}
            </div>
            <div className="settings-item">
              <div
                className="settings-item-header"
                onClick={() =>
                  dropdown === "books"
                    ? setDropdown(null)
                    : setDropdown("books")
                }
              >
                <div className="header-left">
                  <MenuBookIcon className="icon" />
                  Manage Sportsbook
                </div>
                <div className="header-right">
                  <ArrowForwardIosIcon />
                </div>
              </div>
              {dropdown === "books" && (
                <Sportsbook
                  fields={fields}
                  handleFieldChange={handleFieldChange}
                  dailyAccess={dailyAccess}
                  setHasChanged={setHasChanged}
                />
              )}
            </div>
            <div className="settings-item" onClick={onSubscriptionClick}>
              <div className="settings-item-header">
                <div className="header-left">
                  <LockOpenIcon className="icon" />
                  Manage Subscription
                </div>
                <div className="header-right">
                  <ArrowForwardIosIcon />
                </div>
              </div>
            </div>
            <div className="settings-item">
              <div
                className="settings-item-header"
                onClick={() =>
                  dropdown === "feedback"
                    ? setDropdown(null)
                    : setDropdown("feedback")
                }
              >
                <div className="header-left">
                  <EmailIcon className="icon" />
                  Send Feedback
                </div>
                <div className="header-right">
                  <ArrowForwardIosIcon />
                </div>
              </div>
              {dropdown === "feedback" && (
                <Feedback userId={cognitoUser.attributes.sub} />
              )}
            </div>
            <div className="settings-item" id="final">
              <div className="settings-item-header" onClick={onLogoutClick}>
                <div className="header-left">
                  <ExitToAppIcon className="icon" />
                  Logout
                </div>
                <div className="header-right">
                  <ArrowForwardIosIcon />
                </div>
              </div>
            </div>
          </div>
          <Button
            className="submit-button"
            variant="contained"
            color="primary"
            onClick={onSubmitClick}
            disabled={isSubmitDisabled()}
          >
            {isSaving && <Spinner className="spinner" size={20} />}
            Save Updates
          </Button>
        </div>
      )}
    </div>
  );
};

export default Settings;
