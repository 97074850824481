import React from "react";
import Paginations from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Pagination = ({ recordsPerPage, totalRecords, paginate }) => {
  const classes = useStyles();
  let pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRecords / recordsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={classes.root}>
      <Paginations
        count={pageNumbers.length}
        variant="outlined"
        shape="rounded"
        onChange={(event, value) => {
          paginate(value);
        }}
      />
    </div>
  );
};

export default Pagination;
