import React from "react";
import { FixedSizeList } from "react-window";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import "./ContentSupported.scss";

const ContentSupported = () => {
  return (
    <div>
      <div id="content-supported">
        <h2>Leagues Supported</h2>
        <div className="leagues">
          <List component="nav" aria-label="contacts">
            <ListItem button>
              <ListItemText primary="NBA" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="MLB" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="NCAAB" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="UFC" />
            </ListItem>
          </List>
          <List component="nav" aria-label="contacts">
            <ListItem button>
              <ListItemText primary="NFL" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="NHL" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="NCAAF" />
            </ListItem>
          </List>
        </div>
      </div>
      <div id="content-supported">
        <h2>Books Supported</h2>
        <div className="leagues">
          <List component="nav" aria-label="contacts">
            <ListItem button>
              <ListItemText primary="Barstool" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="Bovada" />
            </ListItem>
          </List>
          <List component="nav" aria-label="contacts">
            <ListItem button>
              <ListItemText primary="DraftKings" />
            </ListItem>
            <ListItem button>
              <ListItemText primary="FanDuel" />
            </ListItem>
          </List>
        </div>
      </div>
    </div>
  );
};

export default ContentSupported;
