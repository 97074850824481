import React from "react";

export default [
  {
    title: "Welcome to OddsEye!",
    target: "body",
    placement: "center",
    content: (
      <div>
        <h4>Get notified when the odds improve</h4>
        <h4>Win your bets!</h4>
      </div>
    ),
  },
  {
    title: "Toggle between various sports and leagues",
    target: ".sports-bar-2",
    content: (
      <div>
        <p>Sports that are not in-season will be disabled</p>
      </div>
    ),
  },
  {
    title: "Add odds to your watch slip",
    target: ".single-odds",
    content: (
      <div>
        <p>You can watch spreads, moneylines and totals</p>
      </div>
    ),
  },
  {
    title: "View working and active watch slips",
    target: ".watchSlip",
    content: (
      <div>
        <p>
          Once you find a game you want to track, you can set the thresholds
          here
        </p>
      </div>
    ),
  },
];
