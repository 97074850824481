import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { blue } from "@material-ui/core/colors";

import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import { useAsyncCallback } from "react-async-hook";
import Switch from "@material-ui/core/Switch";
import Container from "@material-ui/core/Container";
import Spinner from "../../components/Spinner/Spinner";
import { API } from "aws-amplify";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./Pricing.scss";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Pricing = ({ cognitoUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [user, setUser] = useState([]);
  const [tierSelected, setTierSelected] = useState("");
  const [isAnnual, setIsAnnual] = useState(false);

  const tiers = [
    {
      title: "Rookie",
      price: "0",
      description: [
        "2 Watch Slips / Day",
        "1 Book Allowed",
        "All-Time Hit % Stats",
      ],
      buttonText: "Rookie",
      value: "ROOKIE",
    },
    {
      title: "Pro 🏅",
      price: isAnnual ? "20" : "2",
      // subheader: "Most popular",
      description: [
        "5 Watch Slips / Day",
        "Unlimited Books Allowed",
        "All-Time Hit % Stats",
      ],
      buttonText: "Pro",
      value: "PRO",
    },
    {
      title: "Hall of Fame 🚀",
      price: isAnnual ? "50" : "5",
      description: [
        "Unlimited Watch Slips / Day",
        "Unlimited Books Allowed",
        "All-Time Hit % Stats",
      ],
      buttonText: "Hall of Fame",
      value: "HALL_OF_FAME",
    },
  ];

  const handlePaymentClick = async (event, plan, price) => {
    setTierSelected(plan);
    setIsPaymentLoading(true);
    if (plan === "ROOKIE") {
      await editUser.execute();
      window.location.reload();
      return;
    }
    // Get Stripe.js instance
    const stripe = await stripePromise;
    const myBody = {
      plan,
      price,
      user,
      isAnnual,
    };
    const myInit = {
      body: myBody,
    };
    // Call your backend to create the Checkout Session
    const session = await API.post("checkout", "/checkout", myInit);
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });
    setIsPaymentLoading(false);
  };

  const editUser = useAsyncCallback(async () => {
    let data = {};
    data.plan = "ROOKIE";
    data.userId = user.userId;
    data.stripeSubscriptionId = null;
    const myInit = {
      body: data,
    };
    await API.post("user", "/user", myInit)
      .then((res) => {
        setIsPaymentLoading(false);
      })
      .catch(() => {
        setIsPaymentLoading(false);
      });
  });

  const ColorSwitch = withStyles({
    switchBase: {
      color: blue[500],
      "&$checked": {
        color: blue[500],
      },
      "&$checked + $track": {
        backgroundColor: blue[300],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleChange = () => {
    setIsAnnual(!isAnnual);
  };

  const isCurrentPlan = (plan, tier) => {
    return (
      (plan === "ROOKIE" && tier.value === "ROOKIE") ||
      (plan === tier.value &&
        ((user.billingCadence == null && !isAnnual) ||
          (user.billingCadence === "Month" && !isAnnual) ||
          (user.billingCadence === "Year" && isAnnual)))
    );
  };

  const params = {
    queryStringParameters: {
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const fetchUserData = useAsyncCallback(async () => {
    const user = await API.get("user", "/user", params);
    setUser(user);
    let annualCadence = false;
    if (user.billingCadence == "Year") {
      annualCadence = true;
    }
    setIsAnnual(annualCadence);
    setIsLoading(false);
  });

  useEffect(() => {
    if (fetchUserData.status === "not-requested") {
      fetchUserData.execute();
    }
  }, [fetchUserData]);

  return (
    <div id="pricing">
      {isLoading ? (
        <Spinner size={60} />
      ) : (
        <React.Fragment>
          {/* Hero unit */}
          <Container maxWidth="sm" component="main" className="hero-content">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Choose the Right Plan For You
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              component="p"
            >
              Find a plan that fits your needs and gives you the best chance to
              beat your bookmaker
            </Typography>
          </Container>
          <div>
            <label>Billed monthly</label>
            <ColorSwitch
              color={isAnnual ? "primary" : "secondary"}
              checked={isAnnual}
              onChange={handleChange}
            />
            <label>Billed annually (save 15%)</label>
          </div>
          {/* End hero unit */}
          <Container>
            <Grid container className="container">
              {tiers.map((tier) => (
                <Grid
                  item
                  key={tier.title}
                  xs={12}
                  sm={6}
                  md={3}
                  className="grid"
                >
                  <Card>
                    <CardHeader
                      title={tier.title}
                      subheader={tier.subheader}
                      titleTypographyProps={{ align: "center" }}
                      subheaderTypographyProps={{ align: "center" }}
                      // action={tier.value === "PRO" ? <StarIcon /> : null}
                      className="card-header"
                    />
                    <CardContent>
                      <div className="card-pricing">
                        <Typography
                          component="h2"
                          variant="h3"
                          color="textPrimary"
                        >
                          ${tier.price}
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                          {isAnnual ? "/yr" : "/mo"}
                        </Typography>
                      </div>
                      <ul>
                        {tier.description.map((line) => (
                          <Typography
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </CardContent>
                    <CardActions className="buy-button">
                      <Button
                        fullWidth
                        variant="contained"
                        disabled={isCurrentPlan(user.plan, tier)}
                        // disabled={true}
                        className="buy-button2"
                        color={"primary"}
                        onClick={(event) =>
                          handlePaymentClick(event, tier.value, tier.price)
                        }
                      >
                        {isPaymentLoading && tierSelected === tier.value ? (
                          <Spinner size={20} />
                        ) : isCurrentPlan(user.plan, tier) ? (
                          "Current Plan"
                        ) : (
                          tier.buttonText
                        )}
                      </Button>
                      {/* </StripeCheckout> */}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
          <br />
          <br />
          <div id="faq">
            <h2 className="questions">Billing FAQs</h2>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="questionText">
                  What happens when I subscribe to a paid plan?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="answersText">
                  Billing occurs right away, and your account will be
                  immediately upgraded to the new tier. You also will receive an
                  email notifying you of the change.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="questionText">
                  When am I billed?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="answersText">
                  Payment happens automatically and is all based on your billing
                  cadence. For example, if you upgraded to the Hall Of Fame tier
                  with the monthly plan, you would be billed $5 that day and for
                  each subsequent month.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="questionText">
                  How do I cancel my plan?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="answersText">
                  You can cancel your plan at any time by downgrading to the
                  Rookie (free) plan. Unfortunately, we are unable to refund the
                  unused portion of your plan. You can rest assured you will not
                  be charged the following pay period.
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className="questionText">
                  How do I reach out with questions relating to my payment?
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography className="answersText">
                  You can send an email to help@oddseye.com and we will be in
                  contact with you as soon as possible!
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Pricing;
