import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useAsyncCallback } from "react-async-hook";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import WatchSlipEntries from "./WatchSlipEntries";
import ActiveWatchSlipEntries from "./ActiveWatchSlipEntries";
import Button from "@material-ui/core/Button";
import { API } from "aws-amplify";
// import { Auth } from "aws-amplify";
// import ExpansionPanel from "@material-ui/core/ExpansionPanel";
// import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
// import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./WatchSlip.scss";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const WatchSlip = ({
  hidingSlip,
  watchSlipData,
  cognitoUser,
  initializeWatchSlip,
  mobile,
  onRemoveWatchSlipEntry,
  updateWatchSlips,
  handleWatchSlipClick,
}) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const [activeSlipData, setActiveSlipData] = useState([]);

  const [count, setCount] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isWatchSlipError, setIsWatchSlipError] = useState(false);

  const [dailyAccess, setDailyAccess] = useState([]);

  const [hack, setHack] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);

  const accessParams = {
    queryStringParameters: {
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const fetchDailyAccess = useAsyncCallback(async () => {
    await API.get("dailyAccess", "/dailyAccess", accessParams).then((res) => {
      setDailyAccess(res);
    });
  });

  useEffect(() => {
    if (fetchDailyAccess.status === "not-requested") {
      fetchDailyAccess.execute();
    }
  }, [fetchDailyAccess, dailyAccess]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue.toString() === "1") {
      fetchActive.execute();
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const onPriceChange = (index, value) => {
    var newValue;
    let currentPlusOrMinus = watchSlipData[index].plusOrMinusPrice;
    if (value === "+" || value === "-" || value === "") {
      newValue = "";
    } else if (!value.includes("+") && currentPlusOrMinus === "plus") {
      newValue = "+" + value;
    } else if (!value.includes("-") && currentPlusOrMinus === "minus") {
      newValue = "-" + value;
    } else {
      newValue = value;
    }
    watchSlipData[index].watchBetPrice = newValue;
    updateWatchSlips(watchSlipData);
    setHack(!hack);
  };

  const onPointsChange = (index, value) => {
    var newValue;
    let currentPlusOrMinus = watchSlipData[index].plusOrMinus;
    let betType = watchSlipData[index].betType;
    if (value === "+" || value === "-" || value === "") {
      newValue = "";
    } else if (
      !value.includes("+") &&
      currentPlusOrMinus === "plus" &&
      betType !== "Total"
    ) {
      newValue = "+" + value;
    } else if (
      !value.includes("-") &&
      currentPlusOrMinus === "minus" &&
      betType !== "Total"
    ) {
      newValue = "-" + value;
    } else {
      newValue = value;
    }
    watchSlipData[index].watchBet = newValue;
    updateWatchSlips(watchSlipData);
    setHack(!hack);
  };

  const onWatchSlipError = () => {
    setIsWatchSlipError(false);
    setIsSuccess(false);
  };

  const onToggleChange = (index, value) => {
    let currentValue = watchSlipData[index].watchBet;
    let currentPlusOrMinus = watchSlipData[index].plusOrMinus;
    if (currentValue && currentPlusOrMinus === "minus" && value === "plus") {
      currentValue = currentValue.replace("-", "+");
    } else if (
      currentValue &&
      currentPlusOrMinus === "plus" &&
      value === "minus"
    ) {
      currentValue = currentValue.replace("+", "-");
    }
    watchSlipData[index].watchBet = currentValue;
    watchSlipData[index].plusOrMinus = value;
    updateWatchSlips(watchSlipData);
    setHack(!hack);
  };

  const onTogglePriceChange = (index, value) => {
    let currentValue = watchSlipData[index].watchBetPrice;
    let currentPlusOrMinus = watchSlipData[index].plusOrMinusPrice;
    if (currentValue && currentPlusOrMinus === "minus" && value === "plus") {
      currentValue = currentValue.replace("-", "+");
    } else if (
      currentValue &&
      currentPlusOrMinus === "plus" &&
      value === "minus"
    ) {
      currentValue = currentValue.replace("+", "-");
    }
    watchSlipData[index].watchBetPrice = currentValue;
    watchSlipData[index].plusOrMinusPrice = value;
    updateWatchSlips(watchSlipData);
    setHack(!hack);
  };

  const postOdds = useAsyncCallback(async (watchSlipData) => {
    for (var i = 0; i < watchSlipData.length; i++) {
      const body = {
        watchSlipData: watchSlipData[i],
        userId: cognitoUser ? cognitoUser.attributes.sub : "",
        phoneNumber: cognitoUser.attributes.phone_number,
      };
      const myInit = {
        body: body,
      };
      await API.post("slip", "/watchslip", myInit)
        .then((res) => {
          if (res["ResponseMetadata"]["HTTPStatusCode"].toString() === "200") {
            setCount((count) => count + 1);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsWatchSlipError(true);
        });
    }
    setIsSubmitting(false);
  });

  const updateDailyAccess = useAsyncCallback(async (numWatchSlips) => {
    dailyAccess.currentWatchSlips += numWatchSlips;
    setDailyAccess(dailyAccess);
  });

  useEffect(() => {
    if (isSubmitting && watchSlipData.length === count) {
      setIsSuccess(true);
      setIsWatchSlipError(false);
      updateDailyAccess.execute(watchSlipData.length);
      initializeWatchSlip(watchSlipData);
      setCount(0);
    }
  }, [
    count,
    initializeWatchSlip,
    isSubmitting,
    updateDailyAccess,
    watchSlipData,
  ]);

  const sortItems = (items) => {
    return items.sort((a, b) => {
      if (a.gameStartDate < b.gameStartDate) {
        return -1;
      }
      if (a.gameStartDate > b.gameStartDate) {
        return 1;
      }
      return 0;
    });
  };

  const params = {
    queryStringParameters: {
      active: true,
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const fetchActive = useAsyncCallback(async () => {
    setIsLoading(true);
    await API.get("slip", "/slip", params).then((res) => {
      setActiveSlipData(sortItems(res));
    });
    setIsLoading(false);
  });

  const onSubmitClick = (watchSlipData) => {
    setIsSubmitting(true);
    postOdds.execute(watchSlipData);
  };

  const isErrorPoints = (value) => {
    return (
      !value ||
      value.length === 0 ||
      isNaN(value) ||
      (value % 1 !== 0 && value % 1 !== 0.5 && value % 1 !== -0.5)
    );
  };

  const isErrorPrice = (value) => {
    if (!value || value.length === 0) {
      return false;
    }
    return (
      isNaN(value) ||
      (value % 1 !== 0 && value % 1 !== 0.5 && value % 1 !== -0.5)
    );
  };

  const isBadInput = (watchSlipData) => {
    if (!watchSlipData || watchSlipData.length === 0) {
      return true;
    }
    for (var i = 0; i < watchSlipData.length; i++) {
      if (isErrorPoints(watchSlipData[i].watchBet)) {
        return true;
      }
    }
    return false;
  };

  const currentWatchSlipsRemaining = (watchSlipData) => {
    if (!dailyAccess["currentWatchSlips"]) {
      dailyAccess["currentWatchSlips"] = 0;
    }
    let watchSlipsRemaining =
      dailyAccess["maxWatchSlips"] -
      dailyAccess["currentWatchSlips"] -
      watchSlipData.length;
    return watchSlipsRemaining;
  };

  return (
    <div
      className="watchSlip"
      style={hidingSlip ? { animation: "slideOutBody 1s ease-in" } : {}}
    >
      <AppBar position={mobile ? "fixed" : "static"} color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className="tabs"
          style={hidingSlip ? { animation: "slideOutTabs 1s ease-in" } : {}}
        >
          <Tab
            className="tabText"
            disableRipple
            label={"Watch Slip (" + watchSlipData.length + ")"}
          />
          <Tab className="tabText" disableRipple label="Active Watch Slips" />
          {mobile && (
            <Button
              disableRipple
              onClick={handleWatchSlipClick}
              color="secondary"
              className="closeButton"
            >
              <h5>X</h5>
            </Button>
          )}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        className="swipeable-views"
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <WatchSlipEntries
            watchSlipData={watchSlipData}
            onPriceChange={onPriceChange}
            onPointsChange={onPointsChange}
            onToggleChange={onToggleChange}
            onTogglePriceChange={onTogglePriceChange}
            isSubmitting={isSubmitting}
            onRemoveWatchSlipEntry={onRemoveWatchSlipEntry}
            isWatchSlipError={isWatchSlipError}
            currentWatchSlipsRemaining={currentWatchSlipsRemaining(
              watchSlipData
            )}
            onWatchSlipError={onWatchSlipError}
            isSuccess={isSuccess}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <ActiveWatchSlipEntries
            activeSlipData={activeSlipData}
            isLoading={isLoading}
          />
        </TabPanel>
      </SwipeableViews>
      {value === 0 && (
        <Button
          disableRipple
          onClick={() => onSubmitClick(watchSlipData)}
          variant="contained"
          color="secondary"
          className="submit"
          disabled={
            isBadInput(watchSlipData) ||
            currentWatchSlipsRemaining(watchSlipData) < 0
          }
        >
          Submit
        </Button>
      )}
    </div>
  );
};

export default WatchSlip;
