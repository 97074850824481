import React, { useRef, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import "./UserIcon.scss";
import MenuIcon from "@material-ui/icons/Menu";
import { useOutsideClickHandler } from "../../shared/hooks/outsideClick";

const UserIcon = ({ cognitoUser, settings, logout, mobile }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleItemClick = (callBack) => {
    toggleMenu();
    if (callBack) {
      callBack();
    }
  };

  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef, () => {
    setMenuOpen(false);
  });

  const toggleMenu = (event) => {
    setMenuOpen(!menuOpen);
  };

  const renderDropdown = () => {
    if (!menuOpen) return null;

    return (
      <div className="profile-dropdown">
        <ul className="profile-menu-list">
          <li
            className="profile-menu-item"
            onClick={() => {
              handleItemClick(settings);
            }}
          >
            Settings
          </li>
          <li
            className="profile-menu-item"
            onClick={() => {
              handleItemClick(logout);
            }}
          >
            Logout
          </li>
        </ul>
      </div>
    );
  };
  return (
    <div className="outer" ref={wrapperRef}>
      <div className="user-dropdown-container">
        <div className="user" onClick={toggleMenu}>
          {!mobile && <Avatar className="avatar"></Avatar>}
          <MenuIcon className="menu-icon" />
        </div>
        {renderDropdown()}
      </div>
    </div>
  );
};

export default UserIcon;
