import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import InputWithIcon from "./InputWithIcon";
import FieldWithValidation from "components/FieldWithValidation";
import { Auth } from "aws-amplify";
import { useFormFields } from "../../shared/hooks/hooksLib";
import { useField } from "hooks";

const ForgotPasswordOverlay = ({ isForgotPassword, handleClose }) => {
  const [page, setPage] = useState("ONE"); // ONE | TWO
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    newPassword: "",
    confirmNewPassword: "",
    resetCode: "",
  });

  const validateFormOne = () => {
    return fields.email && fields.email.length > 0;
  };

  const validateFormTwo = () => {
    return (
      fields.resetCode &&
      fields.resetCode.length > 0 &&
      fields.newPassword &&
      fields.newPassword.length > 0 &&
      fields.newPassword.length < 75 &&
      isValidPassword(fields.newPassword) &&
      fields.confirmNewPassword === fields.newPassword
    );
  };

  const isValidPassword = (password) => {
    const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
  };

  const handleSendCodeClick = async () => {
    try {
      await Auth.forgotPassword(fields.email);
      setPage("TWO");
    } catch (error) {
      console.log(error);
      alert("Error sending reset code. Please try again later.");
    }
  };

  const handleConfirmPasswordReset = async () => {
    try {
      await Auth.forgotPasswordSubmit(
        fields.email,
        fields.resetCode,
        fields.newPassword
      );
    } catch (error) {
      alert("Error resetting password. Please try again later.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (page === "ONE") {
      // if (validateEmail()) {
      handleSendCodeClick();
      // }
    }

    if (page === "TWO") {
      handleConfirmPasswordReset();
      handleClose();
    }
  };

  const pageTwoCancel = () => setPage("ONE");

  return (
    <Dialog
      open={isForgotPassword}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle disableTypography>
        <Typography variant="h4" align="center">
          Forgot Password
        </Typography>
      </DialogTitle>

      {page === "ONE" && (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box
              px={2}
              style={{ minHeight: 100 }}
              display="flex"
              alignItems="center"
            >
              <TextField
                autoFocus
                id="email"
                variant="outlined"
                label="Email"
                onChange={handleFieldChange}
                value={fields.email}
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              style={{ fontSize: "1rem" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              style={{ fontSize: "1rem" }}
              disabled={!validateFormOne()}
            >
              Confirm
            </Button>
          </DialogActions>
        </form>
      )}
      {page === "TWO" && (
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box px={2} minHeight={175}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    autoFocus
                    id="resetCode"
                    label="Code"
                    type="tel"
                    variant="outlined"
                    onChange={handleFieldChange}
                    value={fields.resetCode}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="newPassword"
                    label="New Password"
                    type="password"
                    variant="outlined"
                    onChange={handleFieldChange}
                    value={fields.newPassword}
                    error={
                      fields.newPassword && !isValidPassword(fields.newPassword)
                    }
                    helperText={
                      "Min. of 8 characters, 1 lowercase, 1 uppercase and 1 number."
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="confirmNewPassword"
                    label="Confirm New Password"
                    type="password"
                    variant="outlined"
                    onChange={handleFieldChange}
                    value={fields.confirmNewPassword}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              style={{ fontSize: "1rem" }}
              onClick={pageTwoCancel}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary"
              style={{ fontSize: "1rem" }}
              disabled={!validateFormTwo()}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      )}
    </Dialog>
  );
};

export default ForgotPasswordOverlay;
