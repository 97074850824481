import React from "react";

const BetSettings = ({ fields, handleFieldChange }) => {
  return (
    <div className="radio-buttons">
      <label>
        <input
          id="oddsFormat"
          type="radio"
          checked={fields.oddsFormat === "American"}
          onChange={handleFieldChange}
          value="American"
        />
        <h4>American</h4>
      </label>
      <label>
        <input
          id="oddsFormat"
          type="radio"
          checked={fields.oddsFormat === "Decimal"}
          onChange={handleFieldChange}
          value="Decimal"
        />
        <h4>Decimal</h4>
      </label>
    </div>
  );
};

export default BetSettings;
