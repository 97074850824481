import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles({
  field: {
    padding: "1rem",
    backgroundColor: grey[300],
    "& input": {
      fontSize: "1.5rem",
    },
  },
});

export default function InputWithIcon({ children, ...props }) {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      className={classes.field}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">{children}</InputAdornment>
        ),
      }}
    />
  );
}
