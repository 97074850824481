import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-row">
        <div className="row-inner">
          <ul className="footer-navigation">
            <li>
              <a href="https://twitter.com/OddsEye" target="_blank">
                Twitter
              </a>
            </li>
            <li>
              <a href="mailto:help@oddseye.com" target="_blank">
                Contact
              </a>
            </li>
            {/* <li>
              <a href="https://www.oddseye.com" target="_blank">
                FAQ
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="footer-row">
        <div className="row-inner">
          <ul className="footer-navigation">
            <li>
              <a
                href="https://www.termsfeed.com/live/25c4e462-ed5b-48a8-90a6-66f597359957"
                target="_blank"
              >
                Privacy
              </a>
            </li>
            <li>
              <a
                href="https://www.termsfeed.com/live/e0842ff3-91ec-45b8-89db-c81c765f1251"
                target="_blank"
              >
                Terms
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-row copyright">
        <p className="copyright">© 2021 OddsEye LLC</p>
      </div>
    </div>
  );
};

export default Footer;
