import React, { useState, useEffect } from "react";
import EntryDashboard from "./EntryDashboard";
import EntryHistory from "./EntryHistory";
import EntryStatistics from "./EntryStatistics";
import { useAsyncCallback } from "react-async-hook";
import { API } from "aws-amplify";
import Spinner from "../../components/Spinner/Spinner";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import "./Entry.scss";

const Entry = ({ cognitoUser, mobile }) => {
  const [activeSlipData, setActiveSlipData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState([]);
  const [isFetchAllSlips, setIsFetchAllSlips] = useState(true);

  const getWatchSlipParams = {
    queryStringParameters: {
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const getUserParams = {
    queryStringParameters: {
      userId: cognitoUser ? cognitoUser.attributes.sub : "",
    },
  };

  const fetchWatchSlip = useAsyncCallback(async (fetchNotifiedSlips) => {
    if (fetchNotifiedSlips) {
      getWatchSlipParams.queryStringParameters.notified = true;
    }
    await API.get("slip", "/slip", getWatchSlipParams).then((res) => {
      setActiveSlipData(sortItems(res));
      setIsLoading(false);
    });
  });

  const fetchUserData = useAsyncCallback(async () => {
    const user = await API.get("user", "/user", getUserParams);
    setUser(user);
  });

  useEffect(() => {
    if (fetchWatchSlip.status === "not-requested" && user.length !== 0) {
      fetchWatchSlip.execute(false);
    }
    if (fetchUserData.status === "not-requested") {
      fetchUserData.execute();
    }
  }, [fetchWatchSlip, activeSlipData, fetchUserData]);

  const sortItems = (items) => {
    return items.sort((a, b) => {
      if (a.gameStartDate < b.gameStartDate) {
        return 1;
      }
      if (a.gameStartDate > b.gameStartDate) {
        return -1;
      }
      return 0;
    });
  };

  const ColorSwitch = withStyles({
    switchBase: {
      color: blue[500],
      "&$checked": {
        color: blue[500],
      },
      "&$checked + $track": {
        backgroundColor: blue[300],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const handleChange = () => {
    fetchWatchSlip.execute(isFetchAllSlips);
    setIsFetchAllSlips(!isFetchAllSlips);
  };

  return (
    <div>
      {isLoading ? (
        <div id="entry-cont">
          <Spinner className="spinner" size={60} />
        </div>
      ) : (
        <div id="entry-cont">
          <EntryDashboard
            activeSlipData={activeSlipData}
            user={user}
            mobile={mobile}
          />
          <div>
            <label>Notified Watch Slips</label>
            <ColorSwitch
              color={isFetchAllSlips ? "primary" : "secondary"}
              checked={isFetchAllSlips}
              onChange={handleChange}
            />
            <label>All Watch Slips</label>
          </div>
          <EntryHistory activeSlipData={activeSlipData} />
          <EntryStatistics activeSlipData={activeSlipData} />
        </div>
      )}
    </div>
  );
};

export default Entry;
