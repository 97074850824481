import React, { useState } from "react";
import ReactJoyride, {
  EVENTS,
  CallBackProps,
  ACTIONS,
  STATUS,
} from "react-joyride";

import steps from "../OnboardingSteps/steps";
import mobileSteps from "../OnboardingSteps/mobileSteps";

const OnboardingSteps = ({ mobile, history }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [joyride, setJoyride] = useState({
    run: true,
    steps: mobile ? mobileSteps : steps,
  });

  const handleCallback = (data) => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      setCurrentStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyride({ run: false });
      history.push("/sports/baseball/mlb");
    }
  };

  return (
    <React.Fragment>
      <ReactJoyride
        steps={joyride.steps}
        run={joyride.run}
        continuous
        // showProgress
        showSkipButton
        stepIndex={currentStepIndex}
        callback={handleCallback}
      />
    </React.Fragment>
  );
};

export default OnboardingSteps;
