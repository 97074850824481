import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import emailjs from "emailjs-com";

import FieldWithValidation from "./FieldWithValidation";
import { useField } from "hooks";

const NOTIFICATION = {
  success: {
    icon: CheckCircleIcon,
    message: "Thanks for joining the mailing list!",
  },
  error: {
    icon: ErrorIcon,
    message: "Woops! There was an error. Please try again.",
  },
};

const useNotificationStyles = makeStyles((theme) => ({
  icon: {
    color: green[400],
  },
  message: {
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  center: {
    display: "grid",
    placeItems: "center",
  },
}));

function Notification({ type }) {
  const { message, icon: Icon } = NOTIFICATION[type];
  const classes = useNotificationStyles();
  const iconProps = {};

  if (type === "error") {
    iconProps.color = "error";
  }

  if (type === "success") {
    iconProps.className = classes.icon;
  }

  return (
    <Box p={2}>
      <Grid container justify="center">
        <Box mr={1} className={classes.center}>
          <Icon fontSize="large" {...iconProps} />
        </Box>
        <Typography className={classes.message}>{message}</Typography>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    padding: 20,
    backgroundColor: "white",
    marginBottom: "125px",
  },
  button: {
    fontSize: 16,
  },
  input: {
    fontSize: 16,
  },
  errorText: {
    position: "absolute",
    bottom: -20,
    fontSize: 12,
  },
  spinner: {
    display: "grid",
    placeItems: "center",
  },
});

export default function MailingListSignupForm() {
  const [status, setStatus] = useState("IDLE"); // IDLE | LOADING | SUCCESS | ERROR
  const {
    value,
    inputProps,
    error: mailingListEmailError,
    validateField: validateMailingListEmail,
  } = useField({ type: "email", errorMessage: "Invalid email address" });

  const subscribeToMailingList = (e) => {
    setStatus("LOADING");
    e.preventDefault();
    let params = {
      from_name: value,
    };
    if (validateMailingListEmail()) {
      emailjs
        .send(
          "service_qxjia8h",
          "template_75yvyrj",
          params,
          "user_WyZg3rFjZfc1Sg3nxnlwd"
        )
        .then(
          (result) => {
            setStatus("SUCCESS");
          },
          (error) => {
            setStatus("ERROR");
          }
        );
    }
  };

  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.root}>
      {status === "SUCCESS" ? (
        <Notification type="success" />
      ) : status === "ERROR" ? (
        <Notification type="error" />
      ) : (
        <form onSubmit={subscribeToMailingList}>
          <Grid container direction="row" justify="center">
            <Box flex={1} mr={1}>
              <FieldWithValidation error={mailingListEmailError}>
                <OutlinedInput
                  fullWidth
                  color="secondary"
                  variant="outlined"
                  placeholder="Enter email"
                  className={classes.input}
                  {...inputProps}
                />
              </FieldWithValidation>
            </Box>

            <Button
              disableElevation
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={subscribeToMailingList}
              disabled={status === "LOADING"}
            >
              <>
                {status === "LOADING" && (
                  <Box mr={1} className={classes.spinner}>
                    <CircularProgress color="secondary" size="2rem" />
                  </Box>
                )}
                <Box>JOIN</Box>
              </>
            </Button>
          </Grid>
        </form>
      )}
    </Container>
  );
}
