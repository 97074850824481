import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Auth } from "aws-amplify";
import { useFormFields } from "../../shared/hooks/hooksLib";
import LockIcon from "@material-ui/icons/Lock";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ForgotPasswordOverlay from "./ForgotPasswordOverlay";

import "./LoginOverlay.scss";

const LoginOverlay = ({ isLogin, handleLoggedInSuccessfully, handleClose }) => {
  const [status, setStatus] = useState("LOGIN"); // LOGIN | FORGOT
  const [isSaving, setIsSaving] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });

  const handleSubmit = async () => {
    setIsSaving(true);
    try {
      await Auth.signIn(fields.email, fields.password);
      setIsSaving(false);
      handleLoggedInSuccessfully();
    } catch (e) {
      alert(
        "Error logging in. Please make sure the email + password is correct and try again."
      );
      setIsSaving(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isLogin}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="login-dialog"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle className="dialog-title" id="form-dialog-title">
          Login
          <Button disableRipple onClick={handleClose} className="xButton">
            X
          </Button>
        </DialogTitle>
        <DialogContent>
          <div className="icon-input-container">
            <TextField
              autoFocus
              id="email"
              label="Email"
              variant="outlined"
              onChange={handleFieldChange}
              value={fields.email}
              fullWidth
            />
          </div>
          <div className="icon-input-container">
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              onChange={handleFieldChange}
              value={fields.password}
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className="login-button"
            style={{ fontSize: "1rem" }}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            onClick={() => setStatus("FORGOT")}
            color="primary"
            className="login-button"
            style={{ fontSize: "1rem" }}
            disabled={isSaving}
          >
            Forgot Password
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            className="login-button"
            style={{ fontSize: "1rem" }}
            disabled={
              isSaving ||
              fields.email.length <= 0 ||
              fields.password.length <= 0
            }
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
      {status === "FORGOT" && (
        <ForgotPasswordOverlay
          isForgotPassword={status === "FORGOT"}
          handleClose={() => setStatus("LOGIN")}
        />
      )}
    </div>
  );
};

export default LoginOverlay;
