import React, { useState } from "react";
import { Link } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";
import "./MobileNav.scss";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
// TODO: Take a look at BottomNavigation in MaterialUI
import { useWatchSlipContext } from "contexts/watchSlip";

const StyledBadge = withStyles({
  badge: {
    fontSize: "1.2rem",
  },
})(Badge);

const MobileNav = ({ mobile, cognitoUser, handleNavClick }) => {
  const { watchSlipCount } = useWatchSlipContext();
  const [navButtonClicked, setNavButtonClicked] = useState("home");
  const determineUrl = () => {
    const data = localStorage.getItem("sport-odds");
    if (data) {
      return data;
    }
    return "/sports";
  };

  return (
    <div id="mobile-nav-bar" className="mobile-nav-entire">
      <Link
        to={determineUrl}
        className={"nav-icon-cont"}
        onClick={() => {
          handleNavClick();
          setNavButtonClicked("home");
        }}
      >
        <HomeIcon
          className={
            navButtonClicked === "home"
              ? "nav-icon-clicked"
              : "nav-icon-not-clicked"
          }
        />
        <h4 style={{ color: navButtonClicked === "home" ? "#99badd" : "" }}>
          Home
        </h4>
      </Link>
      <div className="watch-slip-nav">
        <Link
          className="nav-icon-cont"
          onClick={() => {
            handleNavClick(true);
            setNavButtonClicked("watchSlip");
          }}
        >
          <StyledBadge badgeContent={watchSlipCount} color="secondary">
            <MenuBookIcon
              className={
                navButtonClicked === "watchSlip"
                  ? "nav-icon-clicked"
                  : "nav-icon-not-clicked"
              }
            />
          </StyledBadge>
          <h4
            style={{ color: navButtonClicked === "watchSlip" ? "#99badd" : "" }}
          >
            Watch Slip
          </h4>
        </Link>
      </div>
      <Link
        to="/entry"
        className="nav-icon-cont"
        onClick={() => {
          handleNavClick();
          setNavButtonClicked("myEye");
        }}
      >
        <VisibilityIcon
          className={
            navButtonClicked === "myEye"
              ? "nav-icon-clicked"
              : "nav-icon-not-clicked"
          }
        />
        <h4 style={{ color: navButtonClicked === "myEye" ? "#99badd" : "" }}>
          My Eye
        </h4>
      </Link>
      <Link
        to="/settings"
        className="nav-icon-cont"
        onClick={() => {
          handleNavClick();
          setNavButtonClicked("settings");
        }}
      >
        <MenuIcon
          className={
            navButtonClicked === "settings"
              ? "nav-icon-clicked"
              : "nav-icon-not-clicked"
          }
        />
        <h4 style={{ color: navButtonClicked === "settings" ? "#99badd" : "" }}>
          Settings
        </h4>
      </Link>
    </div>
  );
};

export default MobileNav;
