import React from "react";
import "./EntryDashboard.scss";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { Link } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import EventIcon from "@material-ui/icons/Event";

const parseStats = (data) => {
  let stats = [];
  let monthly = {
    hits: 0,
    misses: 0,
    pushes: 0,
  };
  let allTime = {
    hits: 0,
    misses: 0,
    pushes: 0,
  };
  let allTimeCategory = {
    Spread: {
      hits: 0,
      misses: 0,
      pushes: 0,
    },
    Moneyline: {
      hits: 0,
      misses: 0,
      pushes: 0,
    },
    Total: {
      hits: 0,
      misses: 0,
      pushes: 0,
    },
  };
  const currentDate = moment();
  let betType = "";
  for (let i = 0; i < data.length; i++) {
    betType = data[i].betType;
    if (data[i].status === "HIT") {
      allTime.hits += 1;
      allTimeCategory[betType].hits += 1;
      // monthly stats
      if (currentDate.isSame(moment(data[i].gameStartDate), "month")) {
        monthly.hits += 1;
      }
    } else if (data[i].status === "MISS") {
      allTime.misses += 1;
      allTimeCategory[betType].misses += 1;
      if (currentDate.isSame(moment(data[i].gameStartDate), "month")) {
        monthly.misses += 1;
      }
    } else if (data[i].status === "PUSH") {
      allTime.pushes += 1;
      allTimeCategory[betType].pushes += 1;
      if (currentDate.isSame(moment(data[i].gameStartDate), "month")) {
        monthly.pushes += 1;
      }
    }
  }
  stats.push(monthly);
  stats.push(allTime);
  stats.push(allTimeCategory);
  return stats;
};

const EntryDashboard = ({ activeSlipData, user, mobile }) => {
  const statistics = parseStats(activeSlipData);

  const calculateHitPercentage = (hits, misses, pushes) => {
    const totalGames = hits + misses + pushes;
    if (totalGames === 0) {
      return 0;
    }
    return Math.round(((2 * hits + pushes) / (2 * totalGames)) * 100);
  };

  const calculateHitPercentageByCategory = (category) => {
    const totalGames = category.hits + category.misses + category.pushes;
    if (totalGames === 0) {
      return 0;
    }
    return Math.round((category.hits / totalGames) * 100);
  };

  return (
    <div id="dashboard">
      <div className="top">
        <div className="name-and-tag">
          <div className="name-and-edit">
            <h3 className="text">@{user.username}</h3>
            <Link to="/settings">
              <EditIcon className="edit-icon" />
            </Link>
          </div>
          <div className="plan-and-date">
            <Link to="/pricing" style={{ textDecoration: "none" }}>
              <h4 className="user-plan">
                {user.plan ? user.plan.replace(/_/g, " ") : ""}
              </h4>
            </Link>
            <div className="date-joined">
              <EventIcon />
              <h4>Joined {moment.unix(user.createdAt).format("MMM YYYY")}</h4>
            </div>
          </div>
        </div>
        {!mobile ? (
          <div className="current-month-overview">
            <h3>{moment().format("MMM YYYY")} Record</h3>
            <div className="line"></div>
            <div className="month-stats">
              <div>
                <h4>{mobile}Hits</h4>
                <h4>{statistics[0].hits}</h4>
              </div>
              <div>
                <h4>Misses</h4>
                <h4>{statistics[0].misses}</h4>
              </div>
              <div>
                <h4>Pushes</h4>
                <h4>{statistics[0].pushes}</h4>
              </div>
              <div>
                <h4>PCT</h4>
                <h4>
                  {calculateHitPercentage(
                    statistics[0].hits,
                    statistics[0].misses,
                    statistics[0].pushes
                  )}
                  %
                </h4>
              </div>
            </div>
          </div>
        ) : (
          <div className="current-month-overview">
            <h3>{moment().format("MMM YYYY")}</h3>
            <div className="line"></div>
            <div className="month-stats">
              <div>
                <h4 style={{ fontWeight: "bold", fontSize: "1.8rem" }}>
                  {statistics[0].hits +
                    "-" +
                    statistics[0].misses +
                    "-" +
                    statistics[0].pushes +
                    " (" +
                    calculateHitPercentage(
                      statistics[0].hits,
                      statistics[0].misses,
                      statistics[0].pushes
                    ) +
                    "%)"}
                </h4>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="line"></div>
      {!mobile ? (
        <div className="middle">
          <div className="all-time-record">
            <h3>All-Time Record</h3>
            <div className="stats-cont">
              <div>
                <h4>Hits</h4>
                <h4>{statistics[1].hits}</h4>
              </div>
              <div>
                <h4>Misses</h4>
                <h4>{statistics[1].misses}</h4>
              </div>
              <div>
                <h4>Pushes</h4>
                <h4>{statistics[1].pushes}</h4>
              </div>
              <div>
                <h4>PCT</h4>
                <h4>
                  {calculateHitPercentage(
                    statistics[1].hits,
                    statistics[1].misses,
                    statistics[1].pushes
                  )}
                  %
                </h4>
              </div>
            </div>
          </div>
          <div className="all-time-record">
            <div className="all-time-title">
              <h3>All-Time Hit %</h3>
              <Tooltip
                title={
                  <h4>
                    All-Time Hit % is a calculation of the hit frequency by bet
                    type.
                  </h4>
                }
                style={{ fontSize: "24px" }}
                placement="bottom"
              >
                <InfoIcon className="info-icon" />
              </Tooltip>
            </div>
            <div className="stats-cont">
              <div>
                <h4>Spread</h4>
                <h4
                  className={
                    user.plan === "ROOKIE" ? "stats-cont-blur-text" : ""
                  }
                >
                  {calculateHitPercentageByCategory(
                    statistics[2].Spread,
                    statistics[1]
                  )}
                  %
                </h4>
              </div>
              <div>
                <h4>Moneyline</h4>
                <h4
                  className={
                    user.plan === "ROOKIE" ? "stats-cont-blur-text" : ""
                  }
                >
                  {calculateHitPercentageByCategory(statistics[2].Moneyline)}%
                </h4>
              </div>
              <div>
                <h4>Totals</h4>
                <h4
                  className={
                    user.plan === "ROOKIE" ? "stats-cont-blur-text" : ""
                  }
                >
                  {calculateHitPercentageByCategory(statistics[2].Total)}%
                </h4>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="middle">
          <div className="all-time-record">
            <h3>All-Time</h3>
            <div className="stats-cont">
              <div>
                <h4 style={{ fontWeight: "bold", fontSize: "1.6rem" }}>
                  {statistics[1].hits +
                    "-" +
                    statistics[1].misses +
                    "-" +
                    statistics[1].pushes +
                    " (" +
                    calculateHitPercentage(
                      statistics[1].hits,
                      statistics[1].misses,
                      statistics[1].pushes
                    ) +
                    "%)"}
                </h4>
              </div>
            </div>
          </div>
          <div className="all-time-record">
            <div className="all-time-title">
              <h3>All-Time Hit %</h3>
            </div>
            <div className="stats-cont">
              <div>
                <h4>Spread</h4>
                <h4
                  className={
                    user.plan === "ROOKIE" ? "stats-cont-blur-text" : ""
                  }
                >
                  {calculateHitPercentageByCategory(statistics[2].Spread)}%
                </h4>
              </div>
              <div>
                <h4>ML</h4>
                <h4
                  className={
                    user.plan === "ROOKIE" ? "stats-cont-blur-text" : ""
                  }
                >
                  {calculateHitPercentageByCategory(statistics[2].Moneyline)}%
                </h4>
              </div>
              <div>
                <h4>O/U</h4>
                <h4
                  className={
                    user.plan === "ROOKIE" ? "stats-cont-blur-text" : ""
                  }
                >
                  {calculateHitPercentageByCategory(statistics[2].Total)}%
                </h4>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EntryDashboard;
